import { useEffect, useState } from 'react';
import { RichTextEditor } from '@mantine/rte';
import Quill from 'quill';
import ImageResize from 'quill-image-resize-module-react';

// Register the image resize module
Quill.register('modules/imageResize', ImageResize);

function MessagingEditorMantine({body, updateBody}) {
  const [value, onChange] = useState(body);

  const modules = {
    imageResize: {
      modules: ['Resize'],
    }
  };

  useEffect(()=> {
    updateBody(value);
  }, [value])

  return <RichTextEditor controls={[
    ['bold', 'italic', 'underline'],
    ['alignLeft', 'alignCenter', 'alignRight'],
    ['unorderedList', 'orderedList'],
    ['h1', 'h2'],
    ['image']
  ]} 
  value={value} 
  onChange={onChange}
  styles={{
    root: {border: 'none'},
    toolbar: {},
    toolbarInner: { },
    toolbarGroup: { },
    toolbarControl: { },
  }} 
  //modules={modules}
  />;
}

export default MessagingEditorMantine;