import { Component } from 'react';
import { RichTextEditor } from '@mantine/rte';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState, Modifier, RichUtils } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PropTypes from 'prop-types';
import { Group, Loader, Stack } from '@mantine/core';
import RepeatIcon from '@material-ui/icons/Repeat';
import { SwitchContainer } from '../../../../neoverv';
import SaveIcon from '@material-ui/icons/Save';
import BlueTick from '../../../../assets/images/module/general/BlueTick.svg'
import ChordComponent from '../Messaging/decorators/ChordComponent';
import { CompositeDecorator } from 'draft-js';
import { SelectionState } from 'draft-js';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


// const styleMap = {
//     'DEFAULT': {
//         color: '#000'
//     },
//     'TENANTBLOCK': {
//         color: '#4949F5'
//     },
// };

class TilesEditor extends Component{
    decorator= new CompositeDecorator([
      {
        strategy: this.chordStrategy,
        component: ChordComponent,
        props: {
          setSelection: this.setSelection.bind(this)
        }
      }
    ])
    state={
        editorState: EditorState.createEmpty(this.decorator),
        enableTextEditor: false
    }
    constructor(props) {
        super(props);
        // const html = '<h1 style="text-align:center;"><span style="font-weight:bold;">hellohousing</span> Editor</h1>';
        // const contentBlock = htmlToDraft(html);
        // if (contentBlock) {
        //   const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        //   const editorState = EditorState.createWithContent(contentState);
        //   this.state = {
        //     editorState,
        //   };
        // }
      }

      chordStrategy(contentBlock, callback, contentState) {

        function findWithRegex(regex, contentBlock, callback) {
          const text = contentBlock.getText();
          console.log('findWithRegex: ', text);
          let matchArr, start;
          while ((matchArr = regex.exec(text)) !== null) {
            start = matchArr.index;
            callback(start, start + matchArr[0].length);
          }
        }
        findWithRegex(/<a[\s]+([^>]+)>((?:.(?!\<\/a\>))*.)<\/a>/g, contentBlock, callback);
      };

      setSelection(selectionStart, selectionEnd, blockKey) {
        const selectionState = SelectionState.createEmpty(blockKey);
        const newSelectionState = selectionState.merge({
          anchorOffset: selectionStart,
          focusOffset: selectionEnd,
        });
    
        const newEditorState = EditorState.forceSelection(this.state.editorState, newSelectionState);
        console.log('newEditorState: ', newEditorState);
        this.setState({editorState: newEditorState});
      }
      
      componentDidMount(){
        const {body} = this.props;
        if(body){
          this.htmlToDraftConversion(body);
        }
      }

      componentDidUpdate(prevProps){
        const {body} = this.props;
        if(prevProps.body === null && body !== null){
          if(body){
            this.htmlToDraftConversion(body);
          }
        }

      }

      htmlToDraftConversion=(data)=>{
        const contentBlock = htmlToDraft(data);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState1 = EditorState.createWithContent(contentState);
            const editorState2 = EditorState.moveFocusToEnd(editorState1);
            this.onEditorStateChange(editorState2);
            return 'handled';
        }
      }
    
      onEditorStateChange= (editorState) => {
        console.log('editorState: ', editorState);
        this.setState({
          editorState,
        });
      };

      handleKeyCommand=(command, editorState)=>{
        const currentSelection = editorState.getSelection();
        
        const selectionState = editorState.getSelection();
        const anchorKey = selectionState.getAnchorKey();
        const currentContent = editorState.getCurrentContent();
        const currentContentBlock = currentContent.getBlockForKey(anchorKey);
        const start = selectionState.getStartOffset();
        const end = selectionState.getEndOffset();
        const selectedText = currentContentBlock.getText().slice(start, end);

        console.log('selectionState: ', anchorKey, currentContent, currentContentBlock, start, end, selectedText, selectionState.getStartOffset());
        
        // console.log('currentSelection: ', currentSelection);
        // console.log('command_124: ', convertToRaw(editorState.getCurrentContent()));

        // const newState = RichUtils.handleKeyCommand(this.state.editorState, command);
        // if (newState) {
        //   this.onEditorStateChange(newState);
        //   return true;
        // }
        // return false;
        // console.log('newState_1: ', RichUtils.handleKeyCommand(this.state.editorState, command), command);
        if(command === 'backspace'){
          if(window.getSelection().focusNode.data === 'Mieteinheit'){
            const newState = RichUtils.handleKeyCommand(this.state.editorState, 'backspace-word');
            console.log('newState: ', newState, RichUtils.handleKeyCommand(this.state.editorState, 'backspace-word'));
            if (newState) {
              this.onEditorStateChange(newState);
              return true;
            }
            return false;
          }
        }

        // return 'not-handled';
        
        // if(command === 'backspace'){
        //   let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        //   let convertedRaw = convertToRaw(editorState.getCurrentContent());
        //   let convertedRaw2 = convertToRaw(editorState.getCurrentContent());
        //   let text = convertedRaw.blocks[0].text.slice(0, selectionState.getStartOffset()-1);
        //   //let text2 = convertedRaw2.blocks[0].text.slice(selectionState.getStartOffset(), convertedRaw2.blocks[0].text.length);
        //   convertedRaw.blocks[0].text = text;
        //   //convertedRaw2.blocks[0].text = text2;
        //   let newHtml = draftToHtml(convertedRaw);
        //   //let remainingHtml = draftToHtml(convertedRaw2);
          
        //   if(newHtml.slice(newHtml.length - 9, newHtml.length - 5) === '</a>'){
        //     //newHtml.lastIndexOf('<a');
        //     let newHtml1= newHtml.slice(0, newHtml.lastIndexOf('<a')) //remainingHtml.slice(3, remainingHtml.length);
        //     let newHtml2 = newHtml1.slice(0, newHtml.lastIndexOf('<a'))
        //     console.log('handleKeyCommand: ', newHtml2);
        //     this.htmlToDraftConversion(newHtml2);
        //     return 'handled';
        //   }
        // }
        // const newState = RichUtils.onBackspace(editorState);
        // //console.log('handleKeyCommand: ', editorState);
        // if (newState) {
        //   this.onEditorStateChange(newState);
        //   return 'handled';
        // }

        return 'not-handled';
        
      }
    
      render() {
        const { editorState, enableTextEditor } = this.state;
        const { placeholders, allRecipients, messageSavingLoader, subjectInFocus, addPlaceHolderToSubject, onFocus, isMobile } = this.props;
        console.log('toolbarOnFocus: ', isMobile);
        return (
          <Stack>
            <Editor
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              toolbarClassName={isMobile && 'message-body-toolbar-mobile'}
              onEditorStateChange={this.onEditorStateChange}
              localization={{
                locale: 'de',
              }}
              onBlur={()=> {
                let body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
                this.props.updateBody(body);
              }}
              //customStyleMap={styleMap}
              toolbar={{
                inline: {
                  strikethrough: { className: 'rdw-option-hide' },
                  monospace: { className: 'rdw-option-hide' },
                  superscript: { className: 'rdw-option-hide' },
                  subscript: { className: 'rdw-option-hide' },
                },
                // list: {
                //   indent: { className: 'rdw-option-hide' },
                //   outdent: { className: 'rdw-option-hide' },
                // },
                fontSize: {className: 'rdw-option-hide'},
                image: {
                    //icon: image,
                    // className: undefined,
                    // component: undefined,
                    // popupClassName: undefined,
                    urlEnabled: false,
                    uploadEnabled: true,
                    alignmentEnabled: true,
                    uploadCallback: undefined,
                    previewImage: false,
                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                    alt: { present: true, mandatory: true },
                    defaultSize: {
                      height: 'auto',
                      width: 'auto',
                    },
                  },
            }}
            onFocus={()=> onFocus()}
            handleKeyCommand={this.handleKeyCommand}
            />
            {/* <button id="bar" type="button" style={{display: 'none'}}>Fire key event</button> */}
          </Stack>
        );
      }
}

export default TilesEditor;