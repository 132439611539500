import React, { Component } from 'react';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { showNotification } from '@mantine/notifications';
import CopyIcon from '../../../../neoverv/components/SVGs/copyIcon';
import { Box, Group, Modal, Overlay, Stack, Radio, RadioGroup, TextInput, Popper, Popover, CheckboxGroup, Checkbox, Loader, Tabs, Drawer, Text, MultiSelect, SimpleGrid, Grid, CloseButton, Accordion } from '@mantine/core';
import { InputLabel, MenuItem, Select, FormControl, TableContainer, TableHead, TableBody, TableCell, TableRow, Table, IconButton, OutlinedInput, Tooltip } from '@material-ui/core';
import { AddPropertyModal, ColorButton, DeleteModal, EmptyStateContainer, FiltersContainer, MultiselectDropdown, RecordOptions, SearchContainer, SwitchContainer } from '../../../../neoverv';
import PenIcon from '../../../../assets/images/module/general/PenIcon.svg';
import RedCross from '../../../../assets/images/module/general/RedCross.svg';
import RedTick from '../../../../assets/images/module/general/RedTick.svg';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import RentIconGreen from '../../../../assets/images/module/general/RentIconGreen.svg';
import ReceiptsIconBlue from '../../../../assets/images/module/general/ReceiptsIconBlue.svg';
import ReceiptsIcon from '../../../../assets/images/module/general/ReceiptsIconGray.svg';
import PdfIcon from '../../../../assets/images/module/general/PdfNewIcon.svg';
import XlsxIcon from '../../../../assets/images/module/general/XlsxIcon.svg';
import DownloadIcon from '../../../../neoverv/components/SVGs/downloadIcon';
import RentIcon from '../../../../assets/images/module/general/RentIconGray.svg';
import Bank from '../../../../assets/images/module/general/Bank.svg';
import emptyPropertyHome from '.././../../../assets/images/module/general/emptyPropertyHome.svg';
import {
  changeCurrentProperty
} from '../../../../redux/action/General/generalActions';
import {getRentalUnitTenants} from '../../../../redux/action/Tenant/TenantActions'
import dayjs from 'dayjs';
import moment from 'moment';
import { formatNumber } from '../../../../helpers/stringsManipulations';
// import DatePicker from 'react-datepicker';
import { NumericFormat } from 'react-number-format';
import Icon from "../../../../components/Icon";
import UpdateTenantRentModal from '../../../../neoverv/components/Module/General/CustomModal/UpdateTenantRentModal';
import { load, unload } from "@finapi/web-form";
import FinApiWebFormModal from '../../../../neoverv/components/Module/General/CustomModal/FinApiWebFormModal';
import { PageHeaderNew } from '../../../elements';
import accounting from 'accounting';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import UpdateIcon from '@material-ui/icons/Update';
import AssignTransactionModal from '../../../../neoverv/components/Module/General/CustomModal/AssignTransactionModal';
import AddBankConnectionModal from '../../../../neoverv/components/Module/General/CustomModal/AddBankConnectionModal';
import breadcrumbsContent from '../../../../assets/breadcrumbs.json';
import HelpLogo from '../../../../assets/images/module/general/book.svg';
import TourIcon from '../../../../assets/images/module/general/TourIcon.svg';
import TransactionDetailModal from '../../../../neoverv/components/Module/General/CustomModal/TransactionDetailModal';
import BankConnectionExistModal from '../../../../neoverv/components/Module/General/CustomModal/BankConnectionExistModal';
import UtilityBillingAssignedIcon from '../../../../neoverv/components/SVGs/utilityBillingAssigned';
import CloseIcon from '@material-ui/icons/Close';
import RangeDatePicker from '../../../../neoverv/components/Module/General/CustomModal/DateRangePicker';
import { DateRangePicker, DatePicker } from '@mantine/dates';
import SelectOptionFromBankConnectionOrAccountModal from '../../../../neoverv/components/Module/General/CustomModal/SelectOptionFromBankConnectionOrAccountModal';
import SelectAccountsForBankConnectionModal from '../../../../neoverv/components/Module/General/CustomModal/SelectAccountsForBankConnectionModal';
import AddPropertyTypeModal from '../../../../neoverv/components/Module/General/CustomModal/AddPropertyTypeModal';
import LaunchIcon from '@material-ui/icons/Launch';
import BankTransactionsExportRangeModal from '../../../../neoverv/components/Module/General/CustomModal/BankTransactionsExportRangeModal';
import * as XLSX from 'xlsx';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const MOBILE_SIZE = 992;
const TABLET_SIZE = 1420;

const {checkProperty} = Autoload.MainHelper;
let rentalUnits = [];
let rentalListData = [];

function Value({
  value,
  label,
  onRemove,
  classNames,
  ...others
}) {
  console.log('Value: ', others);
  return (
    <div {...others}>
      <Tooltip title={others.disableAssignment ? 'Diese Zahlung ist bereits einem anderen Objekt zugeordnet.' : others.type === 'receipts' ? `Beleg: ${label}` : others.type === 'tenant' && `Mietzahlung: ${label}`}>
        <Group
          style={{
            cursor: 'pointer',
            backgroundColor: others.type === 'receipts' ? '#E3E3FC' : 'rgb(247, 227, 205)',
            border: 'none',
            borderRadius: '10px',
            padding: '5px 12px',
          }}
          position={'apart'}
          align={'center'}
          noWrap
          spacing={8}
        >
          <img 
          src={others.type === 'receipts' ? ReceiptsIcon : RentIcon} 
          style={{width: '14px', height: '14px'}} />
          <span 
          style={{ 
            lineHeight: 1, 
            fontSize: '12px', 
            whiteSpace: 'nowrap', 
            fontWeight: '300', 
            maxWidth: others.multiple && '120px', 
            textOverflow: others.multiple && 'ellipsis', 
            whiteSpace: 'nowrap', 
            overflowX: others.multiple && 'hidden', 
            overflowY: others.multiple && 'hidden', 
            color: '#111147',
            marginRight: others.disableAssignment && '12px'
            }}>
              {label}
          </span>
          {!others.disableAssignment &&
          <Group align={'center'} position='right'>
            <CloseButton
              onMouseDown={()=> others.type === 'receipts' ? others.deleteReceiptAssignment(value) : others.deleteTenantAssignment(value)}
              variant="transparent"
              size={14}
              iconSize={14}
              tabIndex={-1}
              style={{marginRight: (!others.multiple && label.length > 8) && 10}}
            />
          </Group>}
        </Group>
      </Tooltip>
    </div>
  );
}


export class PaymentsList extends Component {
  openTenantSelectionModalRef = null;

  constructor(props) {
    super(props);
    dayjs.locale('de');
    this.state = {
      isMobile: window.innerWidth <= MOBILE_SIZE,
      isTablet: window.innerWidth <= TABLET_SIZE,
      tableHeaderLabels: [{name: 'Sender / Empfänger', width: '15%'}, {name: 'Datum', width: '5%'}, {name: 'Betrag (EUR)', width: '5%'}, {name: 'Beschreibung', width: '20%'}, {name: 'IBAN', width: 'auto'}, {name: 'Ausgabe oder Miete erfassen', width: 'auto'}],
      openWebFormModal: false,
      transactions: [
        // {
        //   senderName: 'Ten1',
        //   bookingDate: dayjs().format('DD.MM.YYYY'),
        //   amount: 450,
        //   reference: '11023837578/ ----',
        //   senderIban: 'xxxx-xxxx-xxxx-xxxx-xxxx',
        //   senderBic: 'xxxxxxxx',
        //   receipts: []
        // }
      ],
      assignTransactionsModalOpen: false,
      addBankConnectionModalOpen: false,
      selectedBank: null,
      deleteBankConnectionModalOpen: false,
      skeletonLoading: false,
      openTransactionDetailModal: false,
      selectedTransaction: null,
      selectedBankConnectionData: null,
      bankConnectionsData: [],
      openBankConnectionExistModal: false,
      webFormUrl: null,
      //bankData: {name: 'Sparkasse Chemnitz', iconUrl: 'https://cdn.finapi.io/assets/images/banks-2024.18.1/icons/DE_SPARKASSE/default.png'},
      loader: false,
      selectedAccount: true,
      openDrawer: false,
      filters: {
        amount: {
          min: null,
          max: null
        },
        bookingDate: {
          startDate: null,
          endDate: null
        }
      },
      transactionLoader: false,
      openSelectOptionFromBankConnectionOrAccountModal: false,
      openAddAccountModal: false,
      bankAccounts: [],
      selectedBankAccount: null,
      transactionsOffset: 0,
      notFound: false,
      getBankConnectionByIdCount: 0,
      updateBankConnectionLoader: false,
      updateBankConnectionInProgress: false,
      addPropertyTypeModal: false,
      addPropertyModal: false,
      selectedPropertyType: null,
      editTransactionAssignment: false,
      tenants: [],
      transactionAssignmentsLoader: [],
      searchTransactions: null,
      accountAccordionState: {0: false},
      openTransactionTooltip: null,
      exportTransactionModalOpen: false,
      openBankTransactionsExportRangeModal: false,
      exportType: null,
      transactionsForExcelExport: []
    };

  }

  componentDidMount(){
    let getAppContent = document.getElementById('app-content');
    getAppContent.addEventListener('scroll', this.handleScroll)
    const {rentalUnits, propertySelected, getRentalUnitTenants} = this.props;
    if(rentalUnits.length === 0){
      getRentalUnitTenants([]);
      if(propertySelected && propertySelected.id !== undefined){
        this.bind_rental_unit_list_data();
      }
    }else if(rentalUnits.length > 0){
      let tenants = [];
      rentalUnits.map((rentalUnit)=> rentalUnit.tenantsData.map((tenant)=> {
        if(tenant.vacant === false){
            tenants.push({...tenant, rentalUnitName: rentalUnit.name, value: tenant.id, label: `${rentalUnit.name}: ${tenant.name} (${tenant.in} - ${tenant.out ? tenant.out : 'aktuell'})`});
        }
      }));
      this.setState({tenants});
    }
    this.setState({loader: true}, ()=> this.getBankConnections());
  }

  getBankConnections=()=>{
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'bankConnections/account',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetBankConnections',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleGetBankConnections(response){
    if(response.status && response.statuscode === 200){
      console.log('handleGetBankConnections: ', response);
      if(response.result.length > 0){
        this.setState({bankConnectionsData: response.result.map((bankConnection)=> ({...bankConnection, bankData: null, bankAccounts: []})), selectedBankConnectionData: response.result[0]}, ()=> {
          //this.putUpdateTransaction();
          // this.getBankAccountsByProperties();
          //this.putUpdateTransaction();
          this.getBankAccountsByProperties();
          this.getBank();
        });
      }else{
        this.setState({selectedBankConnectionData: null});
      }
    }else if(response.status === false && response.statuscode === 400 && response.message === 'NOT_FOUND'){
      this.setState({
        selectedBankConnectionData: null,
        bankConnectionsData: [],
        loader: false
      });
    }else {
      this.setState({loader: false});
    }
  }

  getBankAccountsByProperties=(callPostTransaction=false, selectedLastAccount=false)=>{
    let propertyId = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'bankAccounts/Property/'+ propertyId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetBankAccountsByProperties',
      {callPostTransaction, selectedLastAccount},
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleGetBankAccountsByProperties(response){
    if(response.status && response.statuscode === 200){
      console.log('handleGetBankAccountsByProperties: ', response.result);

      let {bankConnectionsData, selectedBankAccount} = this.state;
      bankConnectionsData = bankConnectionsData.map((bankConnection)=> {
        var allRelatedBankAccounts= response.result.filter((account)=> account.bankConnectionId === bankConnection.id);
        bankConnection.bankAccounts = allRelatedBankAccounts.length > 0 ? allRelatedBankAccounts : [];
        return bankConnection;
      });
      selectedBankAccount= response.other.selectedLastAccount ? response.result.length > 0 ? response.result[response.result.length-1] : null : this.state.selectedBankAccount ? response.result.find((bankAccount)=> bankAccount.id === this.state.selectedBankAccount.id) !== undefined ? this.state.selectedBankAccount : (response.result.length > 0 ? response.result[0] : null) : (response.result.length > 0 ? response.result[0] : null)
      this.setState({
        bankConnectionsData, 
        selectedBankAccount,
        selectedBankConnectionData: selectedBankAccount ? bankConnectionsData.find((bankConnection)=> bankConnection.id === selectedBankAccount.bankConnectionId) : this.state.selectedBankConnectionData
      }, ()=> {
        if(response.other.callPostTransaction){
          this.postTransactions(null, true);
        }else{
          this.getTransactions();
        }
      });//this.postTransactions(null, true)
    }else if(response.message === 'NOT_FOUND'){
      // showNotification({
      //   message: 'Für diese Bankverbindung wurden keine Konten gefunden.',
      //   icon: <img src={RedCross} alt='red-cross'/>,
      //   id: 'noBankAccountsFound'
      // });
      this.setState({loader: false}); //openSelectOptionFromBankConnectionOrAccountModal: true
    }else{
      this.setState({loader: false});
    }
  }

  deleteBankAccountsByProperties=()=>{
    const {selectedBankAccount} = this.state;
    const {propertySelected} = this.props;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + `bankAccountProperties/Property/${propertySelected.id}/BankAccount/${selectedBankAccount.id}`,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleDeleteBankAccountsByProperties',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleDeleteBankAccountsByProperties(response){
    if(response.status && response.statuscode === 200){
      showNotification({
        message: 'Bankkonto erfolgreich gelöscht.',
        icon: <img src={RedTick} alt='red-tick'/>,
        id: 'deleteBankAccount'
      });
    }
    this.setState({deleteBankConnectionModalOpen: false, transactions: [], transactionsOffset: 0}, ()=> this.getBankAccountsByProperties());
  }

  postBankAccountByBankConnectionId=(id=null)=>{
    console.log('postBankAccountByBankConnectionId: ', id);
    const {selectedBankConnectionData} = this.state;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'bankAccounts',
      'POST',
      {
        bankConnectionId: id ? id : selectedBankConnectionData.id
      },
      null,
      false,
      true,
      false,
      this,
      'handlePostBankAccountByBankConnectionId',
      {id},
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    )
  }

  handlePostBankAccountByBankConnectionId(response){
    if(response.status && response.statuscode === 200){
      setTimeout(() => {
        this.getBankAccountByBankConnectionId(response.other.id);  
      }, 1000);
    }
  }

  getBankAccountByBankConnectionId=(bankConnectionId)=>{
    const {selectedBankConnectionData} = this.state;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'bankAccounts/bankConnection/'+ (bankConnectionId ? bankConnectionId : selectedBankConnectionData.id),
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetBankAccountByBankConnectionId',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    )
  }

  handleGetBankAccountByBankConnectionId(response){
    const {bankConnectionsData} = this.state;
    if(response.status && response.statuscode === 200){
      let bankAccounts = response.result;
      let bankExistingAccounts=[];
      bankConnectionsData.map((bankConnection)=> bankConnection.bankAccounts.map((bankAccount)=> bankExistingAccounts.push(bankAccount)));
      bankAccounts = bankExistingAccounts.length > 0 ? bankAccounts.filter((bankAccount)=> bankExistingAccounts.find((bankExistingAccount)=> bankExistingAccount.id === bankAccount.id) === undefined) : bankAccounts;
      this.setState({bankAccounts, openAddAccountModal: true});
    }else if(response.message === 'NOT_FOUND'){
      showNotification({
        message: 'No accounts found',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'noBankAccountFound'
      });
    }
  }

  getBank=()=>{
    const {bankConnectionsData} = this.state;
    bankConnectionsData.map((bankConnection)=> 
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.finance_api_base_url + 'banks/' + bankConnection.bankId,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleGetBank',
        {bankConnection},
        false,
        '',
        {
          // 'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        }
      )
    ); 
  }

  handleGetBank(response){
    let {bankConnectionsData} = this.state;
    if(response.status && response.statuscode === 200){
      bankConnectionsData= bankConnectionsData.map((bankConnection)=> {
        if(bankConnection.id === response.other.bankConnection.id){
          bankConnection.bankData= response.result;
        }
        return bankConnection;
      })
      this.setState({bankConnectionsData});
    }
  }

  getTransactions=()=>{
    this.setState({transactionLoader: true});
    const {selectedBankConnectionData, selectedBankAccount, transactionsOffset, searchTransactions, filters} = this.state;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'transactions/BankAccount/' + selectedBankAccount?.id+`?fetch=10&offset=${transactionsOffset}${filters.amount.min ? '&minimumAmount=' + filters.amount.min : ''}${filters.amount.max ? '&maximumAmount=' + filters.amount.max : ''}${filters.bookingDate.startDate ? '&fromDateTime=' + dayjs(filters.bookingDate.startDate).utc().format() : ''}${filters.bookingDate.endDate ? '&toDateTime=' + dayjs(filters.bookingDate.endDate).utc().format() : ''}${searchTransactions ? '&keyword=' + searchTransactions : ''}`,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetTransactions',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleGetTransactions(response){
    const {transactions, transactionAssignmentsLoader} = this.state;
    if(response.status && response.statuscode === 200){
      this.setState({transactions: [...transactions, ...response.result.map((transaction)=> ({...transaction, transactionAssigments: null}))], transactionAssignmentsLoader: response.result.map((transaction)=> transaction.id)}, ()=> {
        if(response.result.length > 0){
          response.result.map((transaction)=> this.getRecieptsForTransaction(transaction.id));
        }
      });
    }else if(response.message === 'Not Found' && response.statuscode === 404){
      this.setState({notFound: true});
    }
    this.setState({loader: false, transactionLoader: false});
  }

  getRecieptsForTransaction=(transactionId, selectedTransaction=null)=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'ReceiptTenantTransactions/Transaction/' + transactionId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetRecieptsForTransaction',
      {transactionId, selectedTransaction},
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleGetRecieptsForTransaction(response){
    let {transactions, transactionAssignmentsLoader} = this.state;

    const setTransactionAssignments=(value)=>{
      transactions= transactions.map((transaction)=> {
        if(transaction.id === response.other.transactionId){
          transaction.transactionAssigments= {...value, tenant: null};//TODO: add label and value
        }
        return transaction;
      });

      this.setState({transactions, selectedTransaction: response.other.selectedTransaction}, ()=> {
        if(response.result.tenantId){
          console.log('getTenantById: ', response.result);
          this.getTenantById(response.result.tenantId, response.result.transactionId);
        }
      });
    }

    if(response.status && response.statuscode === 200){
      setTransactionAssignments(response.result);
    }else if(response.message === 'NOT_FOUND'){
      setTransactionAssignments(null);
    }

    if(response.result.tenantId === null || response.result.tenantId === undefined){
      transactionAssignmentsLoader= transactionAssignmentsLoader.filter((transactionAssignmentLoader)=> transactionAssignmentLoader !== response.other.transactionId);
      this.setState({transactionAssignmentsLoader});
    }
  }

  getTenantById=(tenantId, transactionId)=>{
    console.log('getTenantById: ', transactionId);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'tenants/' + tenantId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetTenantById',
      {transactionId},
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleGetTenantById(response){
    console.log('handleGetTenantById: ', response);
    // let {transactions} = this.state;
    // if(response.status && response.statuscode === 200){
    //   transactions= transactions.map((transaction)=> {
    //     if(transaction.id === response.other.transactionId){
    //       transaction.transactionAssigments.tenant= {...response.result, value: response.result.id, label: `${response.result.fullName} (${dayjs(response.result.moveInDate).format('DD.MM.YYYY')} - ${response.result.moveOutDate ? dayjs(response.result.moveOutDate).format('DD.MM.YYYY') : 'aktuell'})`};
    //     }
    //     return transaction;
    //   });

    //   this.setState({transactions});
      if(response.status && response.statuscode === 200){
        this.getRentalUnitById(response.result.rentalUnitId, response.result, response.other.transactionId);
      }
    // }
  }

  getRentalUnitById=(rentalUnitId, tenant, transactionId)=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalUnits/' + rentalUnitId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetRentalUnitById',
      {rentalUnitId, tenant, transactionId},
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleGetRentalUnitById(response){
    console.log('handleGetRentalUnitById: ', response);
    let {transactions, transactionAssignmentsLoader} = this.state;
    if(response.status && response.statuscode === 200){
      transactions= transactions.map((transaction)=> {
        if(transaction.id === response.other.transactionId){
          transaction.transactionAssigments.tenant= {...response.other.tenant, value: response.other.tenant.id, label: `${response.result.name}: ${response.other.tenant.fullName} (${dayjs(response.other.tenant.moveInDate).format('DD.MM.YYYY')} - ${response.other.tenant.moveOutDate ? dayjs(response.other.tenant.moveOutDate).format('DD.MM.YYYY') : 'aktuell'})`, rentalUnitName: response.result.name};
        }
        return transaction;
      });

      this.setState({transactions});
    }

    transactionAssignmentsLoader= transactionAssignmentsLoader.filter((transactionAssignmentLoader)=> transactionAssignmentLoader !== response.other.transactionId);
    this.setState({transactionAssignmentsLoader});
  }

  postTransactions=(id=null, eol=true)=>{
    const {selectedBankConnectionData, selectedBankAccount} = this.state;

    this.setState({transactions: [], transactionsOffset: 0, transactionLoader: true});

    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'transactions',
      'POST',
      {
        bankAccountId: id ? id : selectedBankAccount?.id
      },
      null,
      false,
      true,
      false,
      this,
      'handlePostTransaction',
      {eol},
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handlePostTransaction(response){
    if(response.status && response.statuscode === 200){
      showNotification({
        message: 'Kontoumsätze erfolgreich aktualisiert.',
        icon: <img src={GreenTick} alt='green-cross'/>,
        id: 'postTransactionSuccess'
      });
    }
    if(response.other.eol){
      this.getTransactions();
    }
  }

  handlePutUpdateTransaction(response){
    this.getTransactions();
  }


  putUpdateTransaction=()=>{
    const {selectedBankConnectionData} = this.state;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'bankConnections/' + selectedBankConnectionData?.id,
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'handlePutUpdateTransaction',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handlePutUpdateTransaction(response){
    // this.getTransactions();
    setTimeout(() => {
      this.getBankConnectionById(true);  
    }, 3000);

    if(response.status === false){
      this.setState({updateBankConnectionLoader: false});
    }
  }

  getBankConnectionById=(checkBankConnectionStatus=false, callPostBankAccountsByBankConnectionId=false)=> {
    const {selectedBankConnectionData} = this.state;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'bankConnections/'+ selectedBankConnectionData.id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetBankConnectionById',
      {checkBankConnectionStatus, callPostBankAccountsByBankConnectionId},
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleGetBankConnectionById(response){
    const {getBankConnectionByIdCount} = this.state;
    const callGetBankConnectionByIdCount=()=>{
      setTimeout(() => {
        if(getBankConnectionByIdCount <= 5){
          this.setState({getBankConnectionByIdCount: getBankConnectionByIdCount+1}, ()=> this.getBankConnectionById(true, response.other.callPostBankAccountsByBankConnectionId));
        }else{
          this.setState({getBankConnectionByIdCount: 0}, ()=> this.getBankConnectionById(false, response.other.callPostBankAccountsByBankConnectionId));
        }
      }, 2000);
    }

    const onSuccess=()=>{
      this.setState({updateBankConnectionLoader: false});
      if(response.other.callPostBankAccountsByBankConnectionId){
        this.postBankAccountByBankConnectionId();
      }else{
        this.getBankAccountsByProperties(true);
      }
    }
    if(response.status && response.statuscode === 200){
      if(response.other.checkBankConnectionStatus){
        switch (response.result.status) {
          case 'IN_PROGRESS':
            callGetBankConnectionByIdCount();
            break;
          case 'INITIATED':
            callGetBankConnectionByIdCount();
            break;
          case 'COMPLETED':
            onSuccess();
            break;
          case 'WEB_FORM_REQUIRED':
            this.setState({updateBankConnectionLoader: false});
            this.getBankConnectionUrl();
            break;
          case 'AUTHORIZED':
            onSuccess();
            break;
          default:
            onSuccess();
            break;
        }
      }else{
        this.setState({updateBankConnectionLoader: false});
        this.getBankAccountsByProperties();
      }
    }else{
      this.setState({updateBankConnectionLoader: false});
    }
  }

  getBankConnectionUrl=()=>{
    const {selectedBankConnectionData} = this.state;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'bankConnections/'+ selectedBankConnectionData.id + '/Url',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetBankConnectionUrl',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetBankConnectionUrl(response){
    if(response.status && response.statuscode === 200){
      console.log('handleGetBankConnectionUrl: ', response);
      this.openWebForm(response.result, true);
    }
  }

  bind_rental_unit_list_data = () => {
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    // console.log('property id', propertyid,  Autoload.Setting.rental_unit_api_base_url+"?propertyId="+propertyid)
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalUnits?offset=0&fetch=100&propertyId=' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_list_rental_unit',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handle_get_list_rental_unit = (response) => {
    if (response.result === null || response.result && response.result.length === 0) {
      //this.ShowLoader();
    }

    console.log(response, 'add tenant response');
    this.props.getRentalUnitTenants([]);
    if (response.status === false) {
      
    }else{
      rentalUnits = response.result.map((ru) => ({
        id: checkProperty(ru, 'id'),
        name: checkProperty(ru, 'name'),
        size: checkProperty(ru, 'space'),
        propertyId: checkProperty(ru, 'propertyId'),
        tenantsData: [],
      }));
      response.result.map((ru, index) => {
        Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.property_api_base_url + 'tenants?offset=0&fetch=100&rentalUnitId=' + ru.id,
          'GET',
          null,
          null,
          false,
          true,
          false,
          this,
          'handle_get_list_rental_tenants',
          {eol: response.result.length === (index+1)},
          false,
          '',
          {
            //'Content-Type': 'application/json',
            Authorization: Autoload.Utils.getAuthToken(),
          }
        );
      }); 
    }
  };
  handle_get_list_rental_tenants = (response) => {
    rentalListData = this.props.rentalUnits;

    if (response.result.length > 0) {
      //sort for move out
      let dashMoveOut = response.result.filter((item) => item.out === '');
      let filledMovedout = response.result.filter((item) => item.out !== '');
      filledMovedout.map((item) => dashMoveOut.push(item));
      //let current = false;
      let tenantsData = [...dashMoveOut];
      // first vacant false check
      let isFirstVacantFound = false;
      //first vancant false = index
      let firstOriginalDataIndex;

      tenantsData = response.result.map((tenant, idx) => {
        console.log(tenant, idx, 'tenant current idx');
        // isVacant value
        let isVacant = tenant.vacant;
        let current = false;
        if (
          (dayjs(tenant.moveInDate).isBefore(dayjs()) || dayjs(tenant.moveInDate).isSame(dayjs())) //logic for moveInDate
          && 
          (tenant.moveOutDate === null || dayjs(tenant.moveOutDate).isAfter(dayjs()) || dayjs(tenant.moveOutDate).isSame(dayjs())) //logic for moveOutDate
        ) {
          firstOriginalDataIndex = idx;
          current = true;
          isFirstVacantFound = true;
        }else if(tenant.vacant && tenant.moveInDate === null && tenant.moveOutDate === null && response.result.length === 1){
          current = true;
        }

        return {
          id: checkProperty(tenant, 'id'),
          name: checkProperty(tenant, 'fullName'),
          email: checkProperty(tenant, 'email'),
          phone: checkProperty(tenant, 'phone'),
          in: checkProperty(tenant, 'moveInDate') 
            ? moment(checkProperty(tenant, 'moveInDate')).format('DD.MM.YYYY')
            : '',
          out: checkProperty(tenant, 'moveOutDate')
            ? moment(checkProperty(tenant, 'moveOutDate')).format('DD.MM.YYYY')
            : '',
          type: checkProperty(tenant, 'contractType'),
          note: checkProperty(tenant, 'notes'),
          current: current,
          createdOn: checkProperty(tenant, 'createdOn'),
          vacant: checkProperty(tenant, 'vacant'),
          rentalUnitId: checkProperty(tenant, 'rentalUnitId'),
          updatedOn: checkProperty(tenant, 'updatedOn'),
        };
      });
      console.log(tenantsData, '======tenantsData');
      if (tenantsData.length === 1 && tenantsData[0].vacant == true) {
        //tenantsData[0].current = true;
      } else if (firstOriginalDataIndex !== 0 && firstOriginalDataIndex !== undefined) {
        let moveoutDate = tenantsData[firstOriginalDataIndex].out;
        let dateCompare = moment(this.ChangeDateFormat(moveoutDate)).isSameOrAfter(moment(new Date()));
        console.log(moveoutDate, dateCompare, tenantsData[firstOriginalDataIndex], '====abc======');
        if (dateCompare === false) {
          //tenantsData[firstOriginalDataIndex - 1].current = true;
          tenantsData[firstOriginalDataIndex].current = false;
        } else {
          //tenantsData[firstOriginalDataIndex - 1].current = true;
        }
      }

      //action
      //row ko array
      let field = 'name';
      let rentalunitsorted = rentalUnits.sort((a, b) =>
        (a[field] || '').toString().localeCompare((b[field] || '').toString())
      );
      rentalUnits = rentalunitsorted.map((ru) => {
        if (ru.id == tenantsData[0].rentalUnitId) {
          return {
            ...ru,
            tenantsData: [...tenantsData],
          };
        } else {
          return ru;
        }
      });

      console.log('tenant of rental unit', tenantsData, rentalUnits);
    }

    //pass data received to redux
    //this.ShowLoader();
    this.props.getRentalUnitTenants(rentalUnits);
    if(response.other.eol){
      let tenants = [];
      rentalUnits.map((rentalUnit)=> rentalUnit.tenantsData.map((tenant)=> {
        if(tenant.vacant === false){
            tenants.push({...tenant, rentalUnitName: rentalUnit.name, value: tenant.id, label: `${rentalUnit.name}: ${tenant.name} (${tenant.in} - ${tenant.out ? tenant.out : 'aktuell'})`});
        }
      }));
      this.setState({tenants});
    }
    
  };


  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
    if (window.innerWidth <= TABLET_SIZE) {
      this.setState({ isTablet: true });
    } else {
      this.setState({ isTablet: false });
    }
  };

  getTableHeader = () => {
    const {tableHeaderLabels} = this.state;

    return(
      <TableRow>
      {tableHeaderLabels.map((label)=>
        <TableCell classes={{stickyHeader: 'bank-transaction-sticky-header', root: 'bank-transaction-table-cell-root'}} width={label.width} style={{textAlign: label.name === 'Betrag' && 'right', minWidth: label.name === 'IBAN' && '230px'}}>
          {label.name}
        </TableCell>
      )}
      </TableRow>
    )
  };

  onClickForCells=(transaction)=>{
    this.setState({openTransactionDetailModal: true, selectedTransaction: transaction});
  }
  
  getTableBody = () => {
    const {rentalUnits, propertySelected} = this.props;
    const {transactions, tenants, transactionAssignmentsLoader, openTransactionTooltip} = this.state;
    console.log('getTableBody: ', transactions);

    console.log('openTransactionTooltip: ', openTransactionTooltip);

    const renderTableRow=(transaction, disableAssignment)=>{
      return(
        <TableRow style={{background: disableAssignment ? '#f1f3f5' : '#FFFFFF', cursor: 'pointer'}}>
          <TableCell 
          className='cover-space' 
          width={'20%'} 
          style={{height: '71px'}} 
          onClick={()=> this.onClickForCells(transaction)}
          onMouseEnter={()=> disableAssignment && this.setState({openTransactionTooltip: `col1-${transaction.id}`})} 
          onMouseLeave={()=> this.setState({openTransactionTooltip: null})}>
            {transaction.counterpartName}
          </TableCell>
          <TableCell onClick={()=> this.onClickForCells(transaction)}>
            {dayjs(transaction.bankBookingDate).format(dayjs(transaction.bankBookingDate).isSame(dayjs()) ? 'hh:mm Uhr' : 'DD.MM.YYYY')}
          </TableCell>
          <TableCell width={'10%'} style={{textAlign: 'right'}} onClick={()=> this.onClickForCells(transaction)}>
            <span style={{color: transaction.amount < 0 ? '#E8193C' : '#059266', whiteSpace: 'nowrap'}}>{accounting.formatMoney(transaction.amount, { symbol: "",  format: "%v", precision: 2, thousand: '.', decimal: ',' })}</span>
          </TableCell>
          <TableCell className='cover-space' onClick={()=> this.onClickForCells(transaction)}>
            {transaction.purpose}
          </TableCell>
          <TableCell style={{minWidth: '150px'}} className='cover-space' onClick={()=> this.onClickForCells(transaction)}>
            {transaction.counterpartIban}
          </TableCell>
          <TableCell>
            <Group noWrap>
              {/* <MultiselectDropdown
              className="cost-allocation-receipts"
              tableView={true}
              newTable={true}
              //displayValue2="receiptDate"
              // options={transaction.transactionAssigments}
              // selectedValues={this.state.receipts.filter((receipt) => selectedReceipt.find((selected) => (selected.costPositionId === id && selected.receiptId === receipt.id)))} // Preselected value to persist in dropdown
              // onSelect={this.onReceiptSelect} // Function will trigger on select event
              // onRemove={this.onReceiptRemove} // Function will trigger on remove event
              // displayValue="name"
              //placeholder={utilityData.isSecured ? '' : this.props.data.costTypeName !== '' && "Wählen"}
              //viewDetails={true}
              multiSelect
              //disable={utilityData.isSecured || this.props.data.costTypeName === ''}
              //toggleViewDetailsModal={this.toggleViewDetailsModal}
              stopOpen={true}
              onClick={()=> this.setState({assignTransactionsModalOpen: true, selectedTransaction: transaction})}
              /> */}
              <MultiSelect
              data={transaction?.transactionAssigments?.receipts?.length > 0 ? transaction?.transactionAssigments?.receipts?.map((receipt)=> ({...receipt, value: receipt.id, label: receipt.name})) : (transaction?.transactionAssigments?.tenantId && transaction?.transactionAssigments?.tenant)	? [transaction?.transactionAssigments?.tenant] : []}
              value={transaction?.transactionAssigments?.receipts?.length > 0 ? transaction?.transactionAssigments?.receipts?.map((receipt)=> receipt.id) : (transaction?.transactionAssigments?.tenantId && transaction?.transactionAssigments?.tenant)	? [transaction?.transactionAssigments?.tenant.id] : []}
              //onChange={(values)=> this.setState({selectedReceipts: values})}
              className='multi-select-bank-transactions-receipt-assignment'
              placeholder="Ausgabe oder Miete erfassen"
              clearable={false}
              disabled={disableAssignment}
              styles={{
                  disabled: { cursor: 'pointer' },
                  input: {opacity: 1},
                  rightSection: {
                    marginRight: '5px', 
                    marginLeft: '5px', //display: transactionAssignmentsLoader.find((loaderId)=> loaderId === transaction?.id) !== undefined ? 'block' : transaction?.transactionAssigments === null && 'none', 
                    backgroundColor: 'white', 
                    borderTop: '1px solid #ced4da', 
                    borderBottom: '1px solid #ced4da',
                    display: disableAssignment && 'none'
                  },
                  dropdown: {display: 'none'},
                  root: {maxWidth: '250px', minWidth: '250px'},
                  value: {maxWidth: transaction?.transactionAssigments?.receipts?.length > 1 && '120px'},
                  values: {marginRight: !disableAssignment && '20px'}
              }}
              valueComponent={(props)=> 
                <Value 
                  {...props} 
                  type={transaction?.transactionAssigments?.receipts?.length > 0 ? 'receipts' : transaction?.transactionAssigments?.tenantId ? 'tenant' : ''} 
                  multiple={transaction?.transactionAssigments?.receipts?.length > 1 ? true : false} 
                  //onClick={()=> this.setState({assignTransactionsModalOpen: true, selectedTransaction: transaction, editTransactionAssignment: true})}
                  disableAssignment={disableAssignment}
                  deleteReceiptAssignment={(receiptId)=> !disableAssignment && this.deleteTransactionReceiptAssignment(transaction.id, receiptId)}
                  deleteTenantAssignment={(tenantId)=> !disableAssignment && this.deleteTransactionTenantAssignment(transaction.id, tenantId)}
                />
              }
              rightSection={
                transactionAssignmentsLoader.find((loaderId)=> loaderId === transaction?.id) !== undefined ? 
                <Group position='center' align='center' style={{height: '100%'}}>
                  <Loader size={16}/>
                </Group> 
                : 
                <LaunchIcon 
                style={{fontSize: '15px', color: (!disableAssignment && (transaction?.transactionAssigments?.receipts?.length > 0 || transaction?.transactionAssigments?.tenantId)) ? '#5555FF' : '#9A9AB5'}}
                onClick={()=> !disableAssignment && this.setState({assignTransactionsModalOpen: true, selectedTransaction: transaction, editTransactionAssignment: (transaction?.transactionAssigments?.receipts?.length > 0 || transaction?.transactionAssigments?.tenantId)})}
                />
              }
              onClick={()=> !disableAssignment && this.setState({assignTransactionsModalOpen: true, selectedTransaction: transaction, editTransactionAssignment: (transaction?.transactionAssigments?.receipts?.length > 0 || transaction?.transactionAssigments?.tenantId)})}
              />
              {(transaction?.transactionAssigments?.receipts?.length > 0 || transaction?.transactionAssigments?.tenantId) ? <Tooltip title='Ausgabe oder Miete erfasst'>{UtilityBillingAssignedIcon('#5555FF')}</Tooltip> : <Tooltip title='Ausgabe oder Miete nicht erfasst'>{UtilityBillingAssignedIcon('#9A9AB5')}</Tooltip>}
            </Group>
          </TableCell>
        </TableRow>
      )
    }
    return transactions.map((transaction)=> {
      let disableAssignment = (transaction?.transactionAssigments?.receipts?.length > 0 || transaction?.transactionAssigments?.tenantId) ? (propertySelected.id === transaction?.transactionAssigments?.propertyId ? false : true) : false;
      console.log('disableAssignment: ', disableAssignment, transaction, propertySelected.id);
      return(
        // <Group>
        //     <span>{disableAssignment && 'Diese Zahlung ist bereits einem anderen Objekt zugeordnet.'}</span>
        //   </Group>
      disableAssignment ?
      <Tooltip 
      open={disableAssignment && openTransactionTooltip === transaction.id}
      onOpen={()=> disableAssignment && this.setState({openTransactionTooltip: transaction.id})} 
      onClose={()=> this.setState({openTransactionTooltip: null})}
      classes={{popper: 'transaction-row-tooltip-popper', tooltip: 'transaction-row-tooltip-popper'}}
      title={'Diese Zahlung ist bereits einem anderen Objekt zugeordnet.'}
      >
        {renderTableRow(transaction, disableAssignment)}
      </Tooltip>
      :
      renderTableRow(transaction, disableAssignment)
    )})
  };

  openAddBankConnection=()=>{
    const {selectedBankConnectionData, bankConnectionsData} = this.state;
    // if(selectedBankConnectionData){
    //   // this.setState({openBankConnectionExistModal: true});
    //   this.setState({addBankConnectionModalOpen: true});
    // }else{
    //   this.setState({addBankConnectionModalOpen: true});
    // }
    if(bankConnectionsData.length > 0){
      this.setState({openSelectOptionFromBankConnectionOrAccountModal: true});
    }else{
      this.setState({addBankConnectionModalOpen: true});
    }
    
  }

  getBankType=(type)=>{
    switch (type) {
      case 'Checking':
        return 'Girokonto';
      case 'Savings':
        return 'Sparkonto';
      case 'CreditCard':
        return 'Kreditkarte';
      case 'Security':
        return 'Bürgschaft';
      case 'Loan':
        return 'Darlehen';
      case 'Membership':
        return 'Mitgliedschaft';
      case 'Bausparen':
        return 'Bausparkonto';
      default:
        return type;
    }
  }

  renderBankDetailsCard=(isActive=false)=>{
    const {selectedBankConnectionData, bankData, bankConnectionsData, selectedBankAccount, updateBankConnectionLoader} = this.state;
    return (
      <>
        {bankConnectionsData.map((bankConnection)=>
        bankConnection.bankAccounts.map((bankAccount)=> 
        <div style={{display: 'flex', flexDirection: 'column'}} className={`bank-details-card ${selectedBankAccount?.id === bankAccount.id && 'selected-bank-connection'}`} onClick={()=> this.setState({selectedBankConnectionData: bankConnection, selectedBankAccount: bankAccount, transactions: [], transactionsOffset: 0, accountAccordionState: {0: false}}, ()=> this.getTransactions())}>
            <Stack spacing={5}>
              <Group noWrap position='apart'>
                <div style={{display: 'flex', alignItems: 'center', width: 'max-content'}}>
                  <img src={bankConnection?.bankData?.iconUrl} style={{width: '16px'}}/>
                  <span className='bank-details-card-heading-2' style={{marginLeft: 5}}>{`${bankConnection?.bankData?.name}`}</span>
                </div>
                {updateBankConnectionLoader === bankConnection.id && <Loader size={'xs'} />}
              </Group>
              <Grid gutter={0}>
                <Grid.Col span={3}>
                  <span className='bank-details-card-account-name'>BIC: </span>
                </Grid.Col>
                <Grid.Col span={9}>
                  <span className='bank-details-card-iban'>{bankConnection?.bankData?.bic ? bankConnection?.bankData?.bic : '-'}</span>
                </Grid.Col>
                <Grid.Col span={3}>
                  <span className='bank-details-card-account-name'>Art: </span>
                </Grid.Col>
                <Grid.Col span={9}>
                  <span className='bank-details-card-iban'>{bankAccount?.accountType ? this.getBankType(bankAccount?.accountType) : '-'}</span>
                </Grid.Col>
                <Grid.Col span={3}>
                  <span className='bank-details-card-account-name'>IBAN: </span>
                </Grid.Col>
                <Grid.Col span={9}>
                  <span className='bank-details-card-iban'>{bankAccount?.iban ? bankAccount?.iban : '-'}</span>
                </Grid.Col>
                <Grid.Col span={3}>
                  <span className='bank-details-card-account-name'>Inhaber: </span>
                </Grid.Col>
                <Grid.Col span={9}>
                  <span className='bank-details-card-iban'>{bankAccount?.accountHolderName ? bankAccount?.accountHolderName : '-'}</span>
                </Grid.Col>
                <Grid.Col span={3}>
                  <span className='bank-details-card-account-name'>Saldo: </span>
                </Grid.Col>
                <Grid.Col span={9}>
                  <span className='bank-details-card-iban'>{accounting.formatMoney(bankAccount?.balance, '€', 2, '.', ',', '%v %s')}</span>
                </Grid.Col>
              </Grid>
            </Stack>
        </div>
        ))}
        <Group //className='add-new-bank-connection-card' position='right'
        >
          <Tooltip title={'Konto hinzufügen'}>
            <IconButton 
              aria-label="filter list"
              onClick={()=> this.openAddBankConnection()}
              style={{
                background: '#5655FE',
                border: '1px solid #5555FF', 
                boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
                padding: '5px',
                cursor: 'pointer',
                opacity: 1
                }}>
                <AddIcon 
                style={{
                  color: 'white',
                  fontSize: '15px'
                  }}/>
            </IconButton>
          </Tooltip>
        </Group>
      </>
    );
  }

  renderAccountDetailsCard=(isActive=false)=>{
    const {selectedBankConnectionData, bankData, selectedAccount} = this.state;
    return (
    <Group className={`bank-details-card ${selectedAccount && 'selected-bank-connection'}`} spacing={26} noWrap>
      <Stack spacing={5}>
        <Stack spacing={0}>
          <Group>
            <span className='bank-details-card-account-name'>BIC: </span>
            <span className='bank-details-card-iban'>{selectedBankConnectionData?.bic ? selectedBankConnectionData?.bic : '-'}</span>
          </Group>
          <Group>
            <span className='bank-details-card-account-name'>IBAN: </span>
            <span className='bank-details-card-iban'>{selectedBankConnectionData?.iban ? selectedBankConnectionData?.iban : '-'}</span>
          </Group>
          <Group>
            <span className='bank-details-card-account-name'>Inhaber: </span>
            <span className='bank-details-card-iban'>{selectedBankConnectionData?.firstName ? `${selectedBankConnectionData?.firstName} ${selectedBankConnectionData?.lastName}` : '-'}</span>
          </Group>
          <Group>
            <span className='bank-details-card-account-name'>Saldo: </span>
            <span className='bank-details-card-iban'>{accounting.formatMoney(selectedBankConnectionData?.accountBalance, '€', 2, '.', ',', '%v %s')}</span>
          </Group>
        </Stack>
      </Stack>
    </Group>);
  }

  renderActionButtons=()=>{
    const {propertySelected} = this.props;
    const {selectedBankConnectionData} = this.state;
    return (
      <Group spacing={10} noWrap>
        {/* add new account */}
        <Tooltip title={'Konto hinzufügen'}>
          <IconButton 
            aria-label="filter list"
            onClick={()=> (propertySelected && Object.keys(propertySelected).length > 0) && this.openAddBankConnection()}
            style={{
              background: '#5655FE',
              border: '1px solid #DADAE5', 
              boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
              padding: '5px',
              cursor: 'pointer',
              marginRight: '10px',
              opacity: !(propertySelected && Object.keys(propertySelected).length > 0) ? 0.5 : 1
              }}
              disabled={!(propertySelected && Object.keys(propertySelected).length > 0)}>
              <AddIcon 
              style={{
                color: 'white',
                fontSize: '15px'
                }}/>
          </IconButton>
        </Tooltip>
        {/* delete account */}
        <Tooltip title={'Konto löschen'}>
          <IconButton 
          aria-label="filter list"
          //disabled={showRentalUnitsFolder || showTenantsFolders}
          onClick={()=> this.setState({deleteBankConnectionModalOpen: true})} 
          style={{
          background: 'white',
          border: '1px solid #DADAE5', 
          boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
          padding: '5px',
          cursor: 'pointer',
          marginRight: '10px',
          opacity: !selectedBankConnectionData ? 0.5 : 1
          }}
          disabled={!selectedBankConnectionData}>
            <DeleteIcon 
            style={{
              color: '#E8193C',
              fontSize: '15px'
              }}/>
          </IconButton>
        </Tooltip>
        {/* filter */}
        {/* <Tooltip title={'Filtern'}>
          <IconButton 
          aria-label="filter list"
          onClick={()=> this.setState({openDrawer: true})} 
          style={{
          background: 'white',
          border: '1px solid #DADAE5', 
          boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
          padding: '5px',
          cursor: 'pointer',
          opacity: !(propertySelected && Object.keys(propertySelected).length > 0) ? 0.5 : 1
          }}
          disabled={!(propertySelected && Object.keys(propertySelected).length > 0)}>
            <FilterListIcon 
            style={{
            color: '#5655FE',
            fontSize: '15px'
            }}/>
          </IconButton>
        </Tooltip> */}
        {this.renderExport()}
      </Group>
    );
  }

  renderTransactionsActionButtons= ()=> {
    const {propertySelected} = this.props;
    const {selectedBankConnectionData} = this.state;
    return (
      <Group spacing={10} noWrap>
        {/* update account */}
        {/* <Tooltip title={'Konto aktualisieren'}>
          <IconButton 
          aria-label="filter list"
          onClick={()=> selectedBankConnectionData && this.setState({updateBankConnectionLoader: selectedBankConnectionData.id}, ()=> this.putUpdateTransaction())}
          style={{
            background: 'white',
            border: '1px solid #DADAE5', 
            boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
            padding: '5px',
            cursor: 'pointer',
            marginRight: '10px',
            opacity: !selectedBankConnectionData ? 0.5 : 1
          }}
          disabled={!selectedBankConnectionData}
          >
              <UpdateIcon 
              style={{
                color: '#5655FE',
                fontSize: '15px'
                }}/>
          </IconButton>
        </Tooltip> */}
        <div>
          <ColorButton
          text={'Konto aktualisieren'}
          className='purple-btn'
          leftMaterialIcon={
            <Group noWrap style={{height: '21px'}} align='center'>
              <UpdateIcon 
              style={{
              color: 'white',
              fontSize: '14px',
              marginRight: '6px',
              marginTop: '-3px'
              }}/>
            </Group>
          }
          disabled={!(selectedBankConnectionData && selectedBankConnectionData.bankAccounts.length > 0)}
          onClick={()=> (selectedBankConnectionData && selectedBankConnectionData.bankAccounts.length > 0) && this.setState({updateBankConnectionLoader: selectedBankConnectionData.id}, ()=> this.putUpdateTransaction())}
          />
        </div>
      </Group>
    );
  }

  renderAccountsActionButtons= ()=> {
    const {propertySelected} = this.props;
    const {selectedBankConnectionData} = this.state;
    return (
      <Group spacing={10} noWrap>
        {/* add new account */}
        <Tooltip title={'Konto hinzufügen'}>
          <IconButton 
            aria-label="filter list"
            onClick={()=> (propertySelected && Object.keys(propertySelected).length > 0) && this.openAddBankConnection()}
            style={{
              background: '#5655FE',
              border: '1px solid #DADAE5', 
              boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
              padding: '5px',
              cursor: 'pointer',
              marginRight: '10px',
              opacity: !(propertySelected && Object.keys(propertySelected).length > 0) ? 0.5 : 1
              }}
              disabled={!(propertySelected && Object.keys(propertySelected).length > 0)}>
              <AddIcon 
              style={{
                color: 'white',
                fontSize: '15px'
                }}/>
          </IconButton>
        </Tooltip>
        {/* delete account */}
        <Tooltip title={'Konto löschen'}>
          <IconButton 
          aria-label="filter list"
          //disabled={showRentalUnitsFolder || showTenantsFolders}
          onClick={()=> this.setState({deleteBankConnectionModalOpen: true})} 
          style={{
          background: 'white',
          border: '1px solid #DADAE5', 
          boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
          padding: '5px',
          cursor: 'pointer',
          opacity: !selectedBankConnectionData ? 0.5 : 1
          }}
          disabled={!selectedBankConnectionData}>
            <DeleteIcon 
            style={{
              color: '#E8193C',
              fontSize: '15px'
              }}/>
          </IconButton>
        </Tooltip>
      </Group>
    );
  }

  openWebForm=(url, updateBankConnectionInProgress=false)=>{
    this.setState({openWebFormModal: true, webFormUrl: url, addBankConnectionModalOpen: false, updateBankConnectionInProgress: updateBankConnectionInProgress});
  }

  deleteBankConnection=(id=null)=>{
    if(id === null){
      this.setState({loader: true});
    }
    
    const {selectedBankConnectionData} = this.state;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'bankConnections/' + (id ? id : selectedBankConnectionData.id),
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleDeleteBankConnection',
      {id},
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleDeleteBankConnection(response){
    if(response.status && response.statuscode === 200){
      if(response.other.id === null){
        showNotification({
          message: 'Bankkonto gelöscht.',
          icon: <img src={RedTick} alt='red-tick'/>,
          id: 'deleteBankConnection'
        });
      }
    }
    this.setState({deleteBankConnectionModalOpen: false, transactions: [], transactionsOffset: 0}, ()=> response.other.id === null && this.getBankConnections());
  }

  handleChange = (event, type, onBlur=false) => {
    let {filters} = this.state; 

    if(type === 'minCost'){
      filters.amount.min = event.target.value;
    }

    if(type === 'maxCost'){
      filters.amount.max = event.target.value;
    }

    if(type === 'bookingDate'){
      filters.bookingDate.startDate = event[0];
      filters.bookingDate.endDate = event[1];
    }

    if(type === 'startDate'){
      filters.bookingDate.startDate = event;
    }

    if(type === 'endDate'){
      filters.bookingDate.endDate = event;
    }

    this.setState({filters, transactions: onBlur ? [] : this.state.transactions, transactionsOffset: onBlur ? 0 : this.state.transactionsOffset }, () => onBlur && this.getTransactions());
  };

  handleScroll = (e) => {
    console.log('handleScroll: ', e);
    const {loader, transactionsOffset, notFound, transactionLoader} = this.state;
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && transactionLoader === false && notFound === false) { 
      this.setState({transactionsOffset: transactionsOffset + 10},()=> this.getTransactions());
    }
  }

  toggleAddPropertyTypeModal = () => {
    this.setState({ addPropertyTypeModal: !this.state.addPropertyTypeModal });
  };

  toggleAddPropertyModal = () => {
    this.setState({ addPropertyModal: !this.state.addPropertyModal });
  };

  handleSearchHandler = (e, onBlur=false) => {
    this.setState({ searchTransactions: e.target.value, transactions: onBlur ? [] : this.state.transactions, transactionsOffset: onBlur ? this.state.transactionsOffset : 0 }, () => onBlur && this.getTransactions());
  };

  deleteTransactionReceiptAssignment=(transactionId, receiptId)=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + `ReceiptTenantTransactions/Transaction/${transactionId}/Receipt/${receiptId}`,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleDeleteTransactionReceiptAssignment',
      {transactionId},
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  deleteTransactionTenantAssignment=(transactionId, tenantId)=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + `ReceiptTenantTransactions/Transaction/${transactionId}/Tenant/${tenantId}`,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleDeleteTransactionReceiptAssignment',
      {transactionId},
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleDeleteTransactionReceiptAssignment(response){
    if(response.status && response.statuscode === 200){
      showNotification({
        message: 'Zuordnung entfernt',
        icon: <img src={RedTick} alt='red-tick'/>,
        id: 'deleteTransactionReceiptAssignment'
      });

      this.getRecieptsForTransaction(response.other.transactionId);
    }
  }

  downloadExcelTable = (startDate, endDate) => {
    const { selectedBankAccount, selectedBankConnectionData, transactionsForExcelExport } = this.state;
    let firstRow = `Kontoauszüge (${startDate} - ${endDate})`;
    

    var aoa = [
      [firstRow, "", "", "", ""],
      ["", "", "", "", ""],
      ["Bankname:", selectedBankConnectionData?.bankData?.name, "BIC:", selectedBankConnectionData?.bankData?.bic],
      ["Art:", this.getBankType(selectedBankAccount?.accountType), "IBAN:", selectedBankAccount?.iban],
      ["Inhalber:", selectedBankAccount?.accountHolderName, "Saldo:", selectedBankAccount?.balance],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["Sender / Empfänger", "Datum", "Betrag", "Beschreibung", "IBAN"]
    ];

    transactionsForExcelExport.map((transaction)=>
    aoa.push([ 
      transaction.counterpartName, 
      dayjs(transaction.bankBookingDate).format('DD.MM.YYYY'),
      transaction.amount,
      transaction.purpose,
      transaction.counterpartIban
    ]));

    const worksheet = XLSX.utils.aoa_to_sheet(aoa);

    let columns = ["C", "H"];
    columns.forEach((column)=> {
      var colNum = XLSX.utils.decode_col(column); //decode_col converts Excel col name to an integer for col #
      var fmt = '0.00'; // or '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)' or any Excel number format
  
      /* get worksheet range */
      var range = XLSX.utils.decode_range(worksheet['!ref']);
      for(var i = range.s.r + 1; i <= range.e.r; ++i) {
        /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
        var ref = XLSX.utils.encode_cell({r:i, c:colNum});
        /* if the particular row did not contain data for the column, the cell will not be generated */
        if(!worksheet[ref]) continue;
        /* `.t == "n"` for number cells */
        if(worksheet[ref].t != 'n') continue;
        /* assign the `.z` number format */
        worksheet[ref].z = fmt;
      }
    });

    var wscols = [
      {wch:30},
      {wch:20},
      {wch:20},
      {wch:40},
      {wch:30},
      {wch:30}
    ];
    worksheet['!cols'] = wscols;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Kontoauszüge');
    XLSX.writeFile(workbook, `${firstRow}.xlsx`);

    setTimeout(() => {
      this.setState({transactionsForExcelExport: []});
    }, 1000);
  }

  renderExport=()=>{
    const {isMobile, exportTransactionModalOpen, transactions, receipts, selectedBankAccount, exportStartDate, exportEndDate} = this.state;
    const {currentSubscription} = this.props;
    return(
      <Group spacing={5} style={{height: '36px'}}>
        <Popover
        disabled={(currentSubscription.subscriptionKey.split('_')[0] === 'STARTER')}
        opened={exportTransactionModalOpen}
        width={'auto'}
        position="bottom"
        withArrow
        onClose={() => this.setState({exportTransactionModalOpen: false})}
        target={
        <Tooltip title={'Exportieren'}>
          <IconButton 
            aria-label="filter list"
            disabled={transactions.length === 0}
            onClick={()=> this.setState({exportTransactionModalOpen: true})} 
            style={{
              background: 'white',//'#5655FE',
              border: '1px solid #DADAE5', 
              boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
              padding: '5px',
              cursor: 'pointer',
              marginRight: '10px',
              opacity: transactions.length === 0 ? 0.5 : 1
              }}>
              <DownloadIcon size='14px' width='11px' height='11px' fill={'#5655FE'}/>
          </IconButton>
        </Tooltip>
        }
        styles={{
          inner: { padding: '5px'},
        }}>
          <Stack spacing={0}>
            <Group 
            style={{cursor: 'pointer'}}
            sx={() => ({
              padding: '10px',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: '#E3E3FC',
              }
            })}
            onClick={()=> this.setState({openBankTransactionsExportRangeModal: true, exportType: 'excel'})}
            >
              <img src={XlsxIcon} width={'20px'} />
              <span>Excel exportieren</span>
            </Group>
            <Group 
            style={{cursor: 'pointer'}} 
            sx={() => ({
              padding: '10px',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: '#E3E3FC',
              }
            })}
            onClick={()=> this.setState({openBankTransactionsExportRangeModal: true, exportType: 'pdf'})}
            >
              <img src={PdfIcon} width={'20px'} />
              <span>PDF exportieren</span>
            </Group>
          </Stack>
        </Popover>
      </Group>
    )
  }

  getTransactionsForExcelExport=(startDate, endDate)=>{
    const {selectedBankAccount} = this.state;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'transactions/BankAccount/' + selectedBankAccount?.id+`?${startDate ? 'fromDateTime=' + dayjs(startDate, 'DD.MM.YYYY').utc().format() : ''}${endDate ? '&toDateTime=' + dayjs(endDate, 'DD.MM.YYYY').utc().format() : ''}`,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetTransactionsForExcelExport',
      {startDate, endDate},
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleGetTransactionsForExcelExport(response){
    if(response.status && response.statuscode === 200){
      this.setState({transactionsForExcelExport: response.result}, ()=> this.downloadExcelTable(response.other.startDate, response.other.endDate));
    }
  }

  render() {
    const {isMobile, isTablet, openWebFormModal, assignTransactionsModalOpen, addBankConnectionModalOpen, selectedBank, deleteBankConnectionModalOpen, skeletonLoading, openTransactionDetailModal, selectedTransaction, transactions, selectedBankConnectionData, openBankConnectionExistModal, webFormUrl, loader, bankConnectionsData, openDrawer, filters, transactionLoader, openSelectOptionFromBankConnectionOrAccountModal, openAddAccountModal, bankAccounts, selectedAccount, selectedBankAccount, updateBankConnectionLoader, updateBankConnectionInProgress, addPropertyTypeModal, addPropertyModal, selectedPropertyType, editTransactionAssignment, tenants, searchTransactions, accountAccordionState, openBankTransactionsExportRangeModal, exportType} = this.state;
    const {propertySelected, allProperties} = this.props;

    console.log('bankConnectionsData: ', bankConnectionsData);
    console.log('selectedAccount: ', selectedAccount);

    console.log('accountAccordionState: ', selectedBankAccount);
    return (
      <Stack id='owner-document' spacing={0}>
        <Group className={'mt-3'}>
        
        </Group>
        <Group>
          <FiltersContainer
          style={{marginBottom: '0px', width: '100%'}}
          styleSection1={{width: '100%'}}
          hideSection2={true}
          hideSection3={true}
          searchElement={
            <Stack style={{width: '100%'}}>
              {<div style={{display: 'flex'}}>
                {!skeletonLoading && breadcrumbsContent.map((item)=> item.area === '/bank' && 
                item.breadcrumbs.map((breadcrumb)=>
                breadcrumb.type === 'tour' ? breadcrumb.view === (isMobile ? 'mobile' : 'desktop') &&
                <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('startTour', breadcrumb.id)}>
                  <img src={TourIcon} />
                  <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px',}}>{breadcrumb.label}</span>
                </div>
                :
                breadcrumb.view === (isMobile ? 'mobile' : 'desktop') &&
                <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('showArticle', breadcrumb.id)}>
                  <img src={HelpLogo} />
                  <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
                </div>
                )
                )}
              </div>}
              <Group style={{width: '100%'}} noWrap position='apart'>
                <Group noWrap align={'center'}>
                  <SearchContainer
                  placeholder={'Zahlungen durchsuchen'}
                  value={searchTransactions}
                  onChange={this.handleSearchHandler}
                  onBlur={(e)=> this.handleSearchHandler(e, true)}
                  disabled={!(propertySelected && Object.keys(propertySelected).length > 0)}
                  className={isMobile ? "mobile-annual-reading-search" : "annual-reading-search"}
                  />
                  <Tooltip title={'Filtern'}>
                    <IconButton 
                    aria-label="filter list"
                    onClick={()=> this.setState({openDrawer: true})} 
                    style={{
                    background: 'white',
                    border: '1px solid #DADAE5', 
                    boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
                    padding: '5px',
                    cursor: 'pointer',
                    opacity: !(propertySelected && Object.keys(propertySelected).length > 0) ? 0.5 : 1
                    }}
                    disabled={!(propertySelected && Object.keys(propertySelected).length > 0)}>
                      <FilterListIcon 
                      style={{
                      color: '#5655FE',
                      fontSize: '15px'
                      }}/>
                    </IconButton>
                  </Tooltip>
                </Group>
                
                {this.renderActionButtons()}
              </Group>
            </Stack>
          }
          />
        </Group>
        <div className='divider' />
        <Stack style={{marginTop: '16px'}}>
          {allProperties.length > 0 ?
          loader ?
          <Stack style={{width: '100%', height: '50vh'}} align='center' justify={'center'}>
            <Group position='center' align='center'>
              <Loader/>
            </Group>
          </Stack>
          :
          (selectedBankConnectionData && (bankConnectionsData.length > 0 &&
          bankConnectionsData.find((bankConnection)=> bankConnection.bankAccounts.length > 0) !== undefined)) ?
          <>
            {bankConnectionsData.length > 0 &&
            <Accordion 
            classNames={{label: 'bank-account-accordion-label', content: 'bank-account-accordion-content', control: 'bank-account-accordion-control'}}
            state={accountAccordionState}
            onChange={(state)=> this.setState({accountAccordionState: state})}
            >
              <Accordion.Item 
              label={
              <Group noWrap position='apart' align='center'>
                <Group noWrap align={'center'}>
                  <span className='bank-account-heading'>Konto</span>
                  {accountAccordionState[0] === false && 
                  <Group className='bank-details-accordion-card selected-bank-connection' noWrap position='apart' spacing={10}>
                    <div style={{display: 'flex', alignItems: 'center', width: 'max-content'}}>
                      <img src={selectedBankConnectionData?.bankData?.iconUrl} style={{width: '16px'}}/>
                      <span className='bank-details-card-heading-2' style={{marginLeft: 5}}>{`${selectedBankConnectionData?.bankData?.name}`}</span>
                    </div>
                    <span style={{fontSize: '12px', fontWeight: '300'}}>{`(IBAN: ${selectedBankAccount?.iban})`}</span>
                    {updateBankConnectionLoader === selectedBankConnectionData.id && <Loader size={'xs'} />}
                  </Group>}
                </Group>
                {/* {this.renderAccountsActionButtons()} */}
                {this.renderTransactionsActionButtons()}
              </Group>
              }>
                <Group spacing={0}>
                  <Group noWrap className='bank-connections-list' grow align={'center'}>
                    {this.renderBankDetailsCard()}
                  </Group>
                </Group>
              </Accordion.Item>
            </Accordion>}
            
            {/* <Group><span className='bank-account-heading'>Konto</span></Group> */}
            {/* <Group spacing={0}>
              <Group noWrap className='bank-connections-list' grow align={'center'}>
                {this.renderAccountDetailsCard()}
              </Group>
            </Group> */}
            <Group style={{marginTop: '20px'}} noWrap position='apart' align='center'>
              <span className='bank-account-heading'>Zahlungen</span>
              {/* {this.renderTransactionsActionButtons()} */}
            </Group>
            {
            transactionLoader && transactions.length === 0 ?
            <Group style={{width: '100%', marginTop: '100px'}} position='center' align={'center'}>
              <Loader/>
            </Group>
            :
            transactions.length > 0 ? 
            <Group style={{paddingLeft: '3px'}}>
              <TableContainer style={{maxHeight: '400px'}} onScroll={this.handleScroll}>
                <Table className='rental-income-list-table' stickyHeader>
                  <TableHead>
                    {this.getTableHeader()}
                  </TableHead>
                  <TableBody>
                    {this.getTableBody()}
                    {transactionLoader && 
                    <TableRow>
                      <TableCell colSpan={6} style={{textAlign: 'center'}}>
                        <Loader variant='dots'/>
                      </TableCell>
                    </TableRow>}
                  </TableBody>
                </Table>
              </TableContainer>
            </Group>
            :
            <Group style={{width: '100%', marginBottom: '60px'}} position='center'>
              <EmptyStateContainer
              heading={'Keine Zahlungen gefunden.'}
              subheading={'Klicken Sie auf Zahlungen aktualisieren, um neue Zahlungen abzurufen.'}
              showBtn={true}
              btnText={'Zahlungen aktualisieren'}
              btnHandler={()=> this.putUpdateTransaction()}
              icon={Bank}
              />
            </Group>}
          </>
          :
          <Group style={{width: '100%', marginBottom: '60px'}} position='center'>
            <EmptyStateContainer
            heading={'Sie haben noch kein Bankkonto eingerichtet.'}
            subheading={'Verbinden Sie ein Bankkonto mit hellohousing, um Kontoumsätze abzurufen.'}
            showBtn={true}
            btnText={'Konto verbinden'}
            btnHandler={()=> this.openAddBankConnection()}
            icon={Bank}
            />
          </Group>
          :
          <Group style={{width: '100%', marginBottom: '60px'}} position='center'>
            <EmptyStateContainer
            heading={'Bankzahlungen sind noch nicht verfügbar.'}
            subheading="Legen Sie eine Immobilie an, um Bankkonten zu verbinden."
            icon={emptyPropertyHome}
            btnHandler={() => {
              this.toggleAddPropertyTypeModal();
            }}
            btnText={lang.general.text_add_property}
            />
          </Group>}
        </Stack>
        
        {addBankConnectionModalOpen &&
        <AddBankConnectionModal
        open={addBankConnectionModalOpen}
        propertySelected={this.props.propertySelected}
        currentBankConnectionData={selectedBankConnectionData}
        toggle={()=> this.setState({addBankConnectionModalOpen: false})}
        openWebForm={(url, newBankConnectionData)=> {
          this.setState({selectedBankConnectionData: newBankConnectionData, bankConnectionsData: [...bankConnectionsData, {...newBankConnectionData, bankData: null, bankAccounts: []}]}, ()=> this.getBank());
          this.openWebForm(url);
        }}
        deleteBankConnection={(id)=> this.deleteBankConnection(id)}
        />}

        {assignTransactionsModalOpen &&
        <AssignTransactionModal
        open={assignTransactionsModalOpen}
        edit={editTransactionAssignment}
        selectedTransaction={selectedTransaction}
        selectedBankConnectionData={selectedBankConnectionData}
        selectedBankAccount={selectedBankAccount}
        toggle={()=> this.setState({assignTransactionsModalOpen: false, editTransactionAssignment: false}, ()=> selectedTransaction && this.getRecieptsForTransaction(selectedTransaction.id))}
        />}

        {openWebFormModal &&
        <FinApiWebFormModal
        open={openWebFormModal}
        webFormUrl={webFormUrl}
        updateBankConnectionInProgress={updateBankConnectionInProgress}
        heading={updateBankConnectionInProgress ? 'Kontodaten aktualisieren' : 'Bankverbindung hinzufügen'}
        toggle={()=> this.setState({openWebFormModal: false})}
        getBankConnections={()=> this.setState({loader: true, openWebFormModal: false, updateBankConnectionLoader: selectedBankConnectionData.id}, ()=> this.getBankConnectionById(true, true))}//this.postBankAccountByBankConnectionId())}
        getBankConnectionById={()=> this.setState({updateBankConnectionLoader: selectedBankConnectionData.id},()=> this.getBankConnectionById(true))}
        deleteBankConnection={()=> this.deleteBankConnection()}
        />}

        {deleteBankConnectionModalOpen &&
        <DeleteModal
        open={deleteBankConnectionModalOpen}
        record={null}
        textShowModal={'Konto löschen'}
        typeText={`Sind Sie sicher, dass Sie das Bankkonto (IBAN: ${selectedBankAccount?.iban ? selectedBankAccount?.iban : '-'}) löschen wollen? Alle Konto- und Zahlungsinformationen gehen unwiderruflich verloren.`}
        showtext={false}
        handleDelete={()=> {
          let deleteBankConnection = bankConnectionsData.find((bankConnectionData)=> bankConnectionData.bankAccounts.find((bankAccount)=> selectedBankAccount?.id === bankAccount.id) !== undefined);
          if(deleteBankConnection){
            if(deleteBankConnection.bankAccounts.length > 1){
              this.deleteBankAccountsByProperties();
            }else{
              this.deleteBankConnection();
            }
          }else if(bankConnectionsData.length > 0){
            this.deleteBankConnection();
          }
        }}
        toggle={()=> this.setState({deleteBankConnectionModalOpen: false})}
        showDeleteText={false}
        rightButtonText={'Konto löschen'}
        leftButtonText={'Abbrechen'}
        />}

        {openTransactionDetailModal &&
        <TransactionDetailModal
        open={openTransactionDetailModal}
        transaction={selectedTransaction}
        toggle={()=> this.setState({openTransactionDetailModal: false, selectedTransaction: null})}
        />}

        {openBankConnectionExistModal &&
        <BankConnectionExistModal
        open={openBankConnectionExistModal}
        toggle={()=> this.setState({openBankConnectionExistModal: false})}
        openAddBankModal={()=> this.setState({addBankConnectionModalOpen: true, openBankConnectionExistModal: false})}
        />}

        {openSelectOptionFromBankConnectionOrAccountModal &&
        <SelectOptionFromBankConnectionOrAccountModal
        open={openSelectOptionFromBankConnectionOrAccountModal}
        bankConnectionsData={bankConnectionsData}
        getBankAccountByBankConnectionId={(bankConnectionId)=> {
          this.postBankAccountByBankConnectionId(bankConnectionId);
          //this.getBankAccountByBankConnectionId(bankConnectionId);
          this.setState({openSelectOptionFromBankConnectionOrAccountModal: false})
        }}
        toggle={()=> this.setState({openSelectOptionFromBankConnectionOrAccountModal: false})}
        openAddBankModal={()=> this.setState({addBankConnectionModalOpen: true, openSelectOptionFromBankConnectionOrAccountModal: false})}
        openAddAccountModal={()=> this.setState({openSelectOptionFromBankConnectionOrAccountModal: false}, ()=> this.postBankAccountByBankConnectionId())}
        />}

        {openAddAccountModal &&
        <SelectAccountsForBankConnectionModal
        open={openAddAccountModal}
        propertySelected={propertySelected}
        bankAccounts={bankAccounts}
        toggle={()=> this.setState({openAddAccountModal: false})}
        getBankAccountsByPropertyId={()=> this.setState({openAddAccountModal: false}, ()=> this.getBankAccountsByProperties(true, true))}
        />}

        {addPropertyTypeModal &&
        <AddPropertyTypeModal
        className="add_proprty_type_modal"
        open={addPropertyTypeModal}
        toggle={() => {
          this.toggleAddPropertyTypeModal();
        }}
        next={(selectedPropertyType)=> {
          this.setState({selectedPropertyType});
          this.toggleAddPropertyTypeModal();
          this.toggleAddPropertyModal();
        }}
        />}
        {addPropertyModal &&
        <AddPropertyModal
          className="add_proprty_modal"
          edit={false}
          open={addPropertyModal}
          toggle={() => {
            this.toggleAddPropertyModal();
          }}
          propertyType={selectedPropertyType}
        />}
        {openBankTransactionsExportRangeModal &&
        <BankTransactionsExportRangeModal
        open={openBankTransactionsExportRangeModal}
        toggle={() => this.setState({openBankTransactionsExportRangeModal: false, exportType: null})}
        exportTable={(date)=> {
          if(exportType === 'pdf'){
            window.open(`/transactionsPdfExport?selectedBankAccountId=${selectedBankAccount.id}&startDate=${dayjs(date.start).format('DD.MM.YYYY')}&endDate=${dayjs(date.end).format('DD.MM.YYYY')}&bankName=${selectedBankConnectionData?.bankData?.name}&bankIcon=${selectedBankConnectionData?.bankData?.iconUrl}&bic=${selectedBankConnectionData?.bankData?.bic}&iban=${selectedBankAccount.iban}&accountType=${selectedBankAccount.accountType}&accountHolderName=${selectedBankAccount.accountHolderName}&balance=${selectedBankAccount.balance}`, '_blank');
          }else if(exportType === 'excel'){
            this.getTransactionsForExcelExport(dayjs(date.start).format('DD.MM.YYYY'), dayjs(date.end).format('DD.MM.YYYY'));
          }
          
          this.setState({openBankTransactionsExportRangeModal: false, exportType: null});
        }}
        />}
        <Drawer
        opened={openDrawer}
        onClose={() => this.setState({openDrawer: false})}
        padding={'10px'}
        overlayOpacity={0.5}
        position="right"
        size={'300px'}
        styles={{
          header: {
            display: 'none'
          }
        }}
        >
          <Stack>
            <Stack spacing={0}>
              <Group position='apart' style={{marginBottom: '2px'}}>
                <Text sx={(theme) => ({ color: '#111147', fontSize: '16px', margin: '10px' })}>Zahlungen filtern</Text>
                <IconButton size='medium'
                onClick={()=> this.setState({openDrawer: false})}
                >
                  <CloseIcon style={{color: '#727293'}}/>
                </IconButton>
              </Group>
              <div style={{marginTop: 0, marginBottom: 0}} className='divider'/>
            </Stack>
            <Stack spacing={0}>
              <div style={{margin: '0px 15px 15px'}}>
                <InputLabel id="demo-simple-select-outlined-label">Betrag</InputLabel>
                <div style={{display: 'flex'}}>
                <FormControl variant="outlined" style={{width: '120px'}}>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    value={filters.amount.min}
                    onChange={(e)=> this.handleChange(e, 'minCost')}
                    onBlur={(e)=> this.handleChange(e, 'minCost', true)}
                    endAdornment={'€'}
                    placeholder={'Min.'}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                    labelWidth={0}
                    style={{height: '40px'}}
                  />
                </FormControl>
                <div style={{width: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><span style={{color: '#111147'}}> - </span></div>
                <FormControl variant="outlined" style={{width: '120px'}}>
                  <OutlinedInput
                      id="outlined-adornment-weight"
                      value={filters.amount.max}
                      onChange={(e)=> this.handleChange(e, 'maxCost')}
                      onBlur={(e)=> this.handleChange(e, 'maxCost', true)}
                      endAdornment={'€'}
                      placeholder={'Max.'}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                      labelWidth={0}
                      style={{height: '40px'}}
                  />
                </FormControl>
                </div>
              </div>
              <div style={{margin: '0px 15px 15px'}}>
                  <InputLabel id="demo-simple-select-outlined-label">Zeitraum</InputLabel>
                  {/* <DateRangePicker
                  locale='de'
                  inputFormat="DD.MM.YYYY"
                  value={[filters.bookingDate.startDate, filters.bookingDate.endDate]}
                  onChange={(value)=> this.handleChange(value, 'bookingDate')}
                  onBlur={(e)=> this.handleChange([filters.bookingDate.startDate, filters.bookingDate.endDate], 'bookingDate', true)}
                  styles={{
                    input: {
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '14px',
                      fontWeight: '400',
                      height: '40px'
                    },
                    wrapper: {
                      height: '40px'
                    }
                  }}
                  /> */}
                  <Group noWrap spacing={0}>
                    <DatePicker 
                    style={{width: '120px'}} 
                    placeholder="DD.MM.YYYY"
                    inputFormat={'DD.MM.YYYY'}
                    value={filters.bookingDate.startDate} 
                    locale={'de'}
                    onChange={(value)=> this.handleChange(value, 'startDate')}
                    onBlur={(e)=> this.handleChange(filters.bookingDate.startDate, 'startDate', true)}
                    styles={{
                      input: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: '14px',
                        fontWeight: '400',
                        height: '40px'
                      },
                      wrapper: {
                        height: '40px'
                      }
                    }}/>

                    <div style={{width: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><span style={{color: '#111147'}}> - </span></div>

                    <DatePicker 
                    style={{width: '120px'}} 
                    placeholder="DD.MM.YYYY" 
                    inputFormat={'DD.MM.YYYY'}
                    value={filters.bookingDate.endDate} 
                    locale={'de'}
                    onChange={(value)=> this.handleChange(value, 'endDate')}
                    onBlur={(e)=> this.handleChange(filters.bookingDate.endDate, 'endDate', true)}
                    styles={{
                      input: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: '14px',
                        fontWeight: '400',
                        height: '40px'
                      },
                      wrapper: {
                        height: '40px'
                      }
                    }}/>
                  </Group>
              </div>
            </Stack>
          </Stack>
        </Drawer>
      </Stack>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData,
    rentalUnits: state.tenantDataReducer.rentalUnits,
    allProperties: state.generalReducer.allProperties
  };
};

const actions = {
  changeCurrentProperty,
  getRentalUnitTenants
}

export default connect(mapStateToProps, actions)(PaymentsList);
