import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import PdfViewer from '../PdfViewer/PdfViewer';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import { Checkbox, Group, Stack, TextInput } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import { NumericFormat } from 'react-number-format';
import DropFile from '../DropFile/DropFile';
import Autoload from '../../../../Autoload';
import { connect } from 'react-redux';
import {setAddReceiptModal, setReceiptCurrentId, setReceiptFiles, setRefreshReceiptDocumentsData} from '../../../../../redux/action/General/generalActions';
import UtilityBillingAssignedIcon from '../../../SVGs/utilityBillingAssigned';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class MultipleReceiptsUploadModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    selectedFiles: []
  };
  
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    //this.setState({selectedFiles: this.props.selectedFiles});

    setTimeout(() => {
      var elem = document.getElementById("uploadReceiptDocs");
      
      if (typeof elem?.onclick == "function") {
          elem?.onclick?.apply(elem);
          var loader = document.getElementById('hello-housing-loader');
          loader.style.display = 'none';
          this.props.toggle(true);
      }
    }, 1000);
  }

  // componentDidUpdate(){
  //   if(this.state.selectedFiles.length === 1 || this.state.selectedFiles.length === 0){
  //     this.props.setSelectedFiles(this.state.selectedFiles);
  //     this.props.toggle();
  //   }
  // }

  uploadReceipts=()=>{
    if(this.state.selectedFiles && this.state.selectedFiles.length > 0){
      this.props.setAddReceiptModal(true); 
      this.props.setReceiptFiles(this.state.selectedFiles);
      
      setTimeout(() => {
        var elem = document.getElementById("uploadReceiptDocs");
      
        if (typeof elem.onclick == "function") {
            elem.onclick.apply(elem);
            var loader = document.getElementById('hello-housing-loader');
            loader.style.display = 'none';
            this.props.toggle(true);
        }  
      }, 600);
    }
  }

  handleFileChange=(acceptedFiles, rejectedFiles)=>{
    console.log('handleFileChange: ', acceptedFiles, rejectedFiles);
    if(acceptedFiles.length > 0){
      let {selectedFiles} = this.state;
      selectedFiles= [...selectedFiles, ...acceptedFiles.filter((acceptedFile)=> selectedFiles.find((selectedFile)=> selectedFile.path === acceptedFile.path) === undefined)]
      this.setState({selectedFiles});
    }

    if(rejectedFiles.length > 0){
      if(rejectedFiles.find((rejectedFile)=> rejectedFile.errors.find((error)=> error.code === 'file-too-large') !== undefined) !== undefined){
        showNotification({
          message: 'Ausgewählte Dateien überschreiten max. Upload-Dateigröße (max. 20 MB)',
          icon: <img src={RedCross} alt='red-cross'/>,
          id: 'fileSelectionError'
        });
      }
    }
  }

  removeFile=(file)=>{
    let {selectedFiles} = this.state;
    console.log('removeFile: ', file.name, selectedFiles);
    selectedFiles = selectedFiles.filter((selectedFile)=> selectedFile.name !== file.name);
    this.setState({selectedFiles});
  }
  
  render() {
    const { open, toggle, className, currentSubscription } = this.props;
    const { selectedFiles } = this.state;
    
    return (
      <CustomModal
        heading={'Sammel-Upload Belege'}
        headingClassName={'update-received-rent-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
      >
        <Stack style={{width: '100%'}}>
            <Group>
              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: '300'}}>Wählen Sie alle Belegdokumente aus, die Sie hochladen wollen. hellohousing erstellt für jedes Dokument einen Beleg.</span>
            </Group>

            <Stack spacing={10}>
              <Group style={{width: '100%'}}>
                <DropFile
                file={selectedFiles}
                secondText={'JPG, PNG oder PDF, max. 20 MB'}
                multiSelect={true}
                accept={'.jpg,.jpeg,.png,application/pdf'}
                wrapperStyle={{width: '100%'}}
                innerWrapperStyle={{width: '100%'}}
                currentSubscription={currentSubscription}
                showAiEnableLabel={true}
                maxSize={20000000}
                handleDrop={(acceptedFiles, rejectedFiles)=> this.handleFileChange(acceptedFiles, rejectedFiles)}
                removeFile={(file)=> this.removeFile(file)}
                />
              </Group>
            </Stack>
            
            <Group position='apart'>
                <div>
                    <ColorButton
                    text={'Abbrechen'}
                    className='gray-btn'
                    onClick={()=> toggle()}
                    />
                </div>
                <div>
                    <ColorButton
                    text={'Hochladen'}
                    className='purple-btn'
                    onClick={()=> this.uploadReceipts()}
                    />
                </div>
            </Group>
        </Stack>
        <button style={{display: 'none'}} id='uploadReceiptDocs'/>
      </CustomModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData
  };
};

const actions = {
  setAddReceiptModal, 
  setReceiptCurrentId, 
  setReceiptFiles,
  setRefreshReceiptDocumentsData
};

export default connect(mapStateToProps, actions)(MultipleReceiptsUploadModal);
