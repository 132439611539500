import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import PdfViewer from '../PdfViewer/PdfViewer';

export class TenantPortalTilePreviewModal extends Component {
  render() {
    const { open, toggle, className, modalContent} = this.props;

    return (
      <Modal 
      size="lg" 
      backdropTransition={{
        mountOnEnter: true,
        timeout: 0
      }} 
      modalTransition={{timeout: 0}}
      isOpen={open} 
      toggle={() => toggle()} 
      className={`image-modal ${className}`}>
        <ModalHeader toggle={() => toggle()} className="modal-header"></ModalHeader>
        <ModalBody>
          {/* <div style={{backgroundColor: 'rgb(247, 247, 250)', padding: '100px 100px', borderRadius: '4px'}}> */}
            <div className="main-div" style={{ position: 'relative', top: 0, left: 0, paddingTop: 0 }}>
              {modalContent}
            </div>
          {/* </div> */}
        </ModalBody>
      </Modal>
    );
  }
}

export default TenantPortalTilePreviewModal;
