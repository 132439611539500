import React from 'react';
import { Stack, Modal } from '@mantine/core';


const TileDetailsModal= (props) => {
    
    return (
      <Modal
      opened={props.open}
      centered
      overlayOpacity={0.8}
      onClose={() => props.toggle()}
      title={props.tile.title ? props.tile.title : 'No title available'}
      classNames={{
        title: 'tenant-portal-preview-Modal-title',
        body: 'tenant-portal-preview-Modal-body',
        header: 'tenant-portal-preview-Modal-header',
        modal: 'tenant-portal-preview-Modal-modal'
      }}>
        <Stack style={{width: '100%', minHeight: '254px', maxHeight: '100vh'}}>
          <div style={{overflowY: 'scroll'}} dangerouslySetInnerHTML={{__html: props.tile.body}}/>
        </Stack>
      </Modal>
    )
}

export default TileDetailsModal;
