import React, { Component } from 'react';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { hideNotification, showNotification } from '@mantine/notifications';
import RightArrowIcon from '../../../../assets/images/module/general/RightArrowIcon.svg';
import GrayTick from '../../../../assets/images/module/general/GrayTick.svg';
import SelectArrowDown from '../../../../assets/images/module/general/SelectArrowDown.svg';
import UtilityBillingAssignedIcon from '../../../../neoverv/components/SVGs/utilityBillingAssigned';
import SelectArrowUp from '../../../../assets/images/module/general/SelectArrowUp.svg';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';

import MinimizeIcon from '@material-ui/icons/Minimize';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
//import Papa from 'papaparse';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Stack, Group, Select, Accordion, TextInput, Loader } from '@mantine/core';
import { Col, Form, Row } from 'react-bootstrap';
import { ColorButton, SwitchContainer } from '../../../../neoverv';
import TemperatureNotificationModal from '../../../../neoverv/components/Module/General/CustomModal/TemperatureNotificationModal';
//import { EditorState, CompositeDecorator, SelectionState, ContentState, RichUtils} from 'draft-js';
import ChordComponent from '../Messaging/decorators/ChordComponent';
import RichTextEditor from '@mantine/rte';



//import { Editor } from 'react-draft-wysiwyg';
import Editor from './Editor';
import { EditorState, convertToRaw, CompositeDecorator, SelectionState, ContentState, Modifier, RichUtils } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PropTypes from 'prop-types';

import MessagingEditor from '../Messaging/MessagingEditor';
import TilesEditor from './TilesEditor';
import MessagingEditorMantine from '../Messaging/MessagingEditorMantine';


var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

export class TilesForTenantPortal extends Component {
    decorator= new CompositeDecorator([
        {
            strategy: this.chordStrategy,
            component: ChordComponent,
            props: {
            setSelection: this.setSelection.bind(this)
            }
        }
    ]);
    state={
        editorState: EditorState.createEmpty(this.decorator),
        value: null
    }

    // modules = {
    //   // toolbar: [
    //   //   [{ header: '1' }, { header: '2' }, { font: [] }],
    //   //   [{ list: 'ordered' }, { list: 'bullet' }],
    //   //   ['bold', 'italic', 'underline', 'strike'],
    //   //   ['link', 'image'],
    //   //   [{ align: [] }],
    //   //   ['clean'],
    //   // ],
    //   imageResize: {
    //     modules: ['Resize', 'DisplaySize', 'Toolbar'],
    //   },
    // };

    chordStrategy(contentBlock, callback, contentState) {

        function findWithRegex(regex, contentBlock, callback) {
          const text = contentBlock.getText();
          console.log('findWithRegex: ', text);
          let matchArr, start;
          while ((matchArr = regex.exec(text)) !== null) {
            start = matchArr.index;
            callback(start, start + matchArr[0].length);
          }
        }
        findWithRegex(/<a[\s]+([^>]+)>((?:.(?!\<\/a\>))*.)<\/a>/g, contentBlock, callback);
      };

      setSelection(selectionStart, selectionEnd, blockKey) {
        const selectionState = SelectionState.createEmpty(blockKey);
        const newSelectionState = selectionState.merge({
          anchorOffset: selectionStart,
          focusOffset: selectionEnd,
        });
    
        const newEditorState = EditorState.forceSelection(this.state.editorState, newSelectionState);
        console.log('newEditorState: ', newEditorState);
        this.setState({editorState: newEditorState});
      }

      constructor(props) {
        super(props);
        this.typingTimeout = null; // Timer to handle the debounce
      }

      componentDidMount(){
        const {body, id, updateHtml} = this.props;
        if(body){
          this.htmlToDraftConversion(body);
        }
      }

      componentDidUpdate(prevProps){
        const {body} = this.props;
        // if(prevProps.html === null && html !== null)
        if(prevProps.body !== body){
            console.log('componentDidUpdate: ', prevProps.body, body);
          if(body){
            this.htmlToDraftConversion(body);
          }
        }

      }

      htmlToDraftConversion=(data)=>{
        const contentBlock = htmlToDraft(data);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState1 = EditorState.createWithContent(contentState);
            const editorState2 = EditorState.moveFocusToEnd(editorState1);
            this.onEditorStateChange(editorState2);
            return 'handled';
        }
      }
    
      onEditorStateChange= (editorState) => {
        console.log('editorState: ', editorState);
        this.setState({
          editorState
        });
      };

      handleKeyCommand=(command, editorState)=>{
        const currentSelection = editorState.getSelection();
        
        const selectionState = editorState.getSelection();
        const anchorKey = selectionState.getAnchorKey();
        const currentContent = editorState.getCurrentContent();
        const currentContentBlock = currentContent.getBlockForKey(anchorKey);
        const start = selectionState.getStartOffset();
        const end = selectionState.getEndOffset();
        const selectedText = currentContentBlock.getText().slice(start, end);

        console.log('selectionState: ', anchorKey, currentContent, currentContentBlock, start, end, selectedText, selectionState.getStartOffset());
        if(command === 'backspace'){
          if(window.getSelection().focusNode.data === 'Mieteinheit'){
            const newState = RichUtils.handleKeyCommand(this.state.editorState, 'backspace-word');
            console.log('newState: ', newState, RichUtils.handleKeyCommand(this.state.editorState, 'backspace-word'));
            if (newState) {
              this.onEditorStateChange(newState);
              return true;
            }
            return false;
          }
        }

        return 'not-handled';
        
      }

      replaceImgTags=(value)=>{
        let newValue = value;
        newValue= newValue.replaceAll('<img src=', '<img width=\'200px\' src=');
        this.props.updateHtml(newValue);
      }

    handleChange = (value) => {
      this.props.updateHtml(value);
      // Clear the previous timeout if user is still typing
      if (this.typingTimeout) {
        clearTimeout(this.typingTimeout);
      }
  
      // Set a new timeout to trigger the API call after 2 seconds of inactivity
      this.typingTimeout = setTimeout(this.handleUserStoppedTyping, 1500);
    };
  
    handleUserStoppedTyping = () => {
      console.log('User stopped typing. Making API call...');
      // Replace this with your API call
      this.props.updateHtml(this.props.body, true);
      this.typingTimeout = null;
    };
  
    render() {
        const {title, id, body, updateTitle, updateHtml, deleteTile, displayOrder, loader, dragStart, index} = this.props;
        const {editorState, value} = this.state;

        console.log('body: ', body);
        return (
            <Stack 
            spacing={10}
            style={{
                width: '100%'
            }}>
                {loader ?//loader.find((tileId)=> tileId === id) !== undefined ?
                <Group position='center' align={'center'}>
                    <Loader/>
                </Group>
                :
                !dragStart ?
                <Stack>
                <TextInput
                label={'Title'}
                placeholder={'Aushänge'}
                value={title}
                styles={{
                    label: {
                        fontSize: '12px',
                        fontWeight: 400,
                        color: '#727293'
                    }
                }}
                onChange={(e)=> updateTitle(e.target.value)}
                onBlur={()=> updateTitle(title, true)}
                />
                {/* <RichTextEditor
                value={body}
                onChange={this.handleChange} //value.includes('<img src=') ? this.replaceImgTags(value) : updateHtml(value)
                //onBlur={()=> updateHtml(body, true)}
                onImageUpload={(image)=> null}
                controls={[
                    ['bold', 'italic', 'underline'],
                    ['alignLeft', 'alignCenter', 'alignRight'],
                    ['unorderedList', 'orderedList'],
                    ['h1', 'h2'],
                    ['image']
                ]}
                modules={this.modules}
                /> */}
                <MessagingEditorMantine
                body={body}
                updateBody={this.handleChange}
                />
                {/* <Editor/> */}
                  {/* <TilesEditor
                  placeholders={[]}
                  allRecipients={[]}
                  messageSavingLoader={loader}
                  body={body}
                  updateBody={(body)=> this.handleChange(body)}
                  subjectInFocus={false}
                  onFocus={()=> null}
                  addPlaceHolderToSubject={(html)=> null}
                  //isMobile={isMobile}
                  /> */}
                </Stack>
                :
                <Group position='center' align={'center'} style={{height: '100%'}}>
                    <span style={{fontWeight: 'bolder', fontSize: '20px', opacity: 0.5}}>{displayOrder}</span>
                </Group>}
                
                {/* <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                //toolbarClassName={isMobile && 'message-body-toolbar-mobile'}
                onEditorStateChange={this.onEditorStateChange}
                //toolbarOnFocus={isMobile}
                localization={{
                    locale: 'de',
                }}
                onBlur={()=> {
                    //let body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
                    //this.props.updateBody(body);
                }}
                //customStyleMap={styleMap}
                toolbar={{
                    inline: {
                    strikethrough: { className: 'rdw-option-hide' },
                    monospace: { className: 'rdw-option-hide' },
                    superscript: { className: 'rdw-option-hide' },
                    subscript: { className: 'rdw-option-hide' },
                    },
                    // list: {
                    //   indent: { className: 'rdw-option-hide' },
                    //   outdent: { className: 'rdw-option-hide' },
                    // },
                    fontSize: {className: 'rdw-option-hide'}
                }}
                //onFocus={()=> onFocus()}
                //toolbarCustomButtons={isMobile ? [] : [<CustomOption  placeholders={placeholders} savingLoader={messageSavingLoader} subjectInFocus={subjectInFocus} addPlaceHolderToSubject={addPlaceHolderToSubject}/>]}
                handleKeyCommand={this.handleKeyCommand}
                /> */}
            </Stack>
        );
    }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};
export default connect(mapStateToProps)(TilesForTenantPortal);
