import React, { Component } from 'react';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { hideNotification, showNotification } from '@mantine/notifications';
import RightArrowIcon from '../../../../assets/images/module/general/RightArrowIcon.svg';
import GrayTick from '../../../../assets/images/module/general/GrayTick.svg';
import SelectArrowDown from '../../../../assets/images/module/general/SelectArrowDown.svg';
import UtilityBillingAssignedIcon from '../../../../neoverv/components/SVGs/utilityBillingAssigned';
import SelectArrowUp from '../../../../assets/images/module/general/SelectArrowUp.svg';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import RedTick from '../../../../assets/images/module/general/RedTick.svg';
import RedCross from '../../../../assets/images/module/general/RedCross.svg';
import MinimizeIcon from '@material-ui/icons/Minimize';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
//import Papa from 'papaparse';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Stack, Group, Select, Accordion, Loader, SimpleGrid } from '@mantine/core';
import { Col, Form, Row } from 'react-bootstrap';
import { ColorButton, DeleteModal, SwitchContainer } from '../../../../neoverv';
import TemperatureNotificationModal from '../../../../neoverv/components/Module/General/CustomModal/TemperatureNotificationModal';
import TilesForTenantPortal from './TilesForTenantPortal';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import CloseButtonIcon from '../../../../assets/images/module/general/CloseButtonIcon.svg';
import {arrayMove, SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TenantPortalTilePreviewModal from '../../../../neoverv/components/Module/General/CustomModal/TenantPortalTilePreviewModal';
import TileDetailsModal from '../../../../neoverv/components/Module/General/CustomModal/TileDetailsModal';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const pStyle= {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgb(14, 14, 29)'
}
const tableStyle= {
    border: 'none',
    padding: '5px 0px',
    textAlign: 'center'
}
const tableHeaderStyle= {
    // fontWeight: '500', 
    // lineHeight: '16px',
    // fontFamily: 'Inter, sans-serif',
    // whiteSpace: 'nowrap',
    // width: '200px',
    // maxWidth: '200px',
    // textAlign: 'left'
    whiteSpace: 'nowrap',
    border: 'none',
    paddingBottom: '0px',
    fontSize: '12px',
    fontWeight: '400',
    color:'#727293',
    width: '80px',
    width: '200px',
    maxWidth: '200px',
    textAlign: 'left'
}

const DragHandle = sortableHandle(() => 
<DragIndicatorIcon
style={{
    width: '16px', 
    color: '#727293', 
    cursor: 'grab',
    marginTop: '4px'
}}
/>);

const SortableItem = SortableElement(({tile, handleSort, dragTileOver, dragTile, index, setState, dragStart, loader, deleteTile, updateTitle, updateHtml, updateIsActive, update}) => 
<Group
//draggable
// onDragStart={() => {
//     dragTile.current = index;
//     setState({dragStart: true});
// }}
//onDragEnter={() => (dragTileOver.current = index)}
//onDragEnd={handleSort}
//onDragOver={(e) => e.preventDefault()}
style={{
    background: '#F7F7FA',
    boxShadow: '0 1px 3px rgba(0,0,0,.08)',
    borderRadius: '4px',
    width: '100%',
    padding: '20px',
    height: (dragStart || loader) ? '150px' : '500px', // loader.find((tileId)=> tileId === tile.id) !== undefined
    //transition: 'height 0.02s',
}}
>
    <Group noWrap style={{width: '100%'}}>
        <DragHandle/>
        {!loader && !dragStart &&
        <Group position='apart' style={{width: '100%'}}>
            <Group>
                <SwitchContainer
                checked={tile.isActive}
                switchText={'aktivieren'}
                disabled={false}
                onChange={()=> updateIsActive(tile.id, !tile.isActive)}
                />
            </Group>
            <Group 
            onClick={()=> {
                console.log('deleteTile: ', tile.id);
                deleteTile(tile.id);
            }}>
                <img src={CloseButtonIcon} style={{cursor: 'pointer', padding: '0px 10px', marginTop: '4px'}}/>
            </Group>
        </Group>}                                        
    </Group>
    
    <TilesForTenantPortal
    body={tile.body}
    id={tile.id}
    title={tile.title}
    displayOrder={tile.displayOrder}
    loader={loader}
    dragStart={dragStart}
    index={index}
    updateTitle={(title, onBlur=false)=> {
        updateTitle(tile.id, title);
        if(onBlur){
            update(tile.id);
        }
    }}
    updateHtml={(body, onBlur=false)=> {
        updateHtml(tile.id, body);
        if(onBlur){
            update(tile.id);
        }
    }}
    deleteTile={()=> {
        console.log('deleteTile: ', tile.id);
        deleteTile(tile.id)
    }}
    />
</Group>);

const SortableList = SortableContainer(({items, handleSort, dragTileOver, dragTile, setState, dragStart, loader, deleteTile, updateTitle, updateHtml, updateIsActive, update}) => {
  return (items.sort((a, b)=> a.displayOrder - b.displayOrder)
    .map((tile, index) => (
        <SortableItem 
        key={`item-${tile.id}`} 
        index={index} 
        tile={tile} 
        // handleSort={handleSort} 
        // dragTileOver={dragTileOver} 
        // setState={setState} 
        // dragStart={dragStart} 
        update={(id)=> update(id)}
        loader={loader} 
        deleteTile={(tileId)=> deleteTile(tileId)} 
        updateTitle={(tileId, title)=> updateTitle(tileId, title)} 
        updateHtml={(tileId, body)=> updateHtml(tileId, body)}
        updateIsActive={(tileId, isActiveStatus)=> updateIsActive(tileId, isActiveStatus)}
        />
    ))
  );
});

export class TenantAdministrateTilesSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            minimize: true,
            tiles: [
                // {
                //     body: '<p>test 1</p>',
                //     id: 0,
                //     title: 'title 1',
                //     propertyId: '0000',
                //     displayOrder: 1,
                //     isActive: false,

                // },
                // {
                //     body: '<p>test 2</p>',
                //     id: 1,
                //     title: 'title 2',
                //     propertyId: '0000',
                //     displayOrder: 2,
                //     isActive: false,

                // },
                // {
                //     body: '<p>test 3</p>',
                //     id: 2,
                //     title: 'title 3',
                //     propertyId: '0000',
                //     displayOrder: 3,
                //     isActive: false,

                // }//,
                // {
                //     id: 'add',
                //     body: null,
                //     displayOrder: 4,
                //     title: 'Add',
                // }
                // {
                //     body: null,
                //     id: 1,
                //     title: null,
                // },
                // {
                //     body: null,
                //     id: 2,
                //     title: null,
                // }
            ],
            loader: false,//[]
            dragStart: false,
            mainLoader: false,
            openTenantPortalTilePreviewModal: false,
            openTileDetailModal: false,
            tileDataForPreview: null,
            individualLoader: null,
            openDeleteModal: false,
            deleteTileId: null
        };
        this.dragTile = React.createRef();
        this.dragTileOver = React.createRef();
    }

    componentDidMount(){
        this.getPropertyNotes();
    }

    addTile=()=>{
        let {tiles} = this.state;
        this.postPropertyNotes({
            body: '<p></p>',
            title: null,
            displayOrder: tiles.length > 0 ? (tiles[tiles.length - 1].displayOrder + 1) : 1,
            isActive: true,
        });
        // tiles.pop();
        // tiles.push({
        //     body: `<p>test body ${tiles.length > 0 ? (tiles[tiles.length - 1].displayOrder + 1) : 1}</p>`,
        //     id: tiles.length,
        //     title: `Test title ${tiles.length > 0 ? (tiles[tiles.length - 1].displayOrder + 1) : 1}`,
        //     propertyId: '0000',
        //     displayOrder: tiles.length > 0 ? (tiles[tiles.length - 1].displayOrder + 1) : 1,
        //     isActive: true,
        // });
        

        // tiles.push({
        //     id: 'add',
        //     body: null,
        //     //displayOrder: tiles.length > 0 ? (tiles[tiles.length - 1].displayOrder + 1) : 1,
        //     title: 'Add',
        // });
        //this.setState({tiles});
    }

    randomRecep=()=>{
        return Math.floor((Math.random() * 3));
       }
       
    assignTemp=(tempArr)=>{
        const number = this.randomRecep();
        if (tempArr.includes( number ) ) {
        console.warn( `${number} exists in [${tempArr.join(', ')}]` );
        return this.assignTemp(tempArr);
        }
        console.warn( `adding ${number} to [${tempArr.join(', ')}]` );
        tempArr.push( number );
        return tempArr;
    }

    updateTile=(id, tileField, value, onBlur=false)=>{
        let {tiles} = this.state;
        tiles = tiles.map((tile)=> {
            if(tile.id === id){
                tile[tileField] = value;
                return tile;
            }else{
                return tile;
            }
        });
        this.setState({tiles}, ()=> {
            if(onBlur){
                this.putPropertyNotes(id);
            }
        });
    }

    // updateTitle=(id, title, onBlur=false)=>{
    //     let {tiles} = this.state;
    //     tiles = tiles.map((tile)=> {
    //         if(tile.id === id){
    //             tile.title = title;
    //             return tile;
    //         }else{
    //             return tile;
    //         }
    //     });
    //     this.setState({tiles}, ()=> {
    //         if(onBlur){
    //             this.putPropertyNotes(id);
    //         }
    //     });
    // }

    // updateHtml=(id, body, onBlur=false)=>{
    //     let {tiles} = this.state;
    //     tiles = tiles.map((tile)=> {
    //         if(tile.id === id){
    //             tile.body = body;
    //             return tile;
    //         }else{
    //             return tile;
    //         }
    //     });
    //     this.setState({tiles}, ()=> {
    //         if(onBlur){
    //             this.putPropertyNotes(id);
    //         }
    //     });
    // }

    // updateIsActive=(id, isActive)=>{
    //     let {tiles} = this.state;
    //     tiles = tiles.map((tile)=> {
    //         if(tile.id === id){
    //             tile.isActive = isActive;
    //             return tile;
    //         }else{
    //             return tile;
    //         }
    //     });
    //     this.setState({tiles}, ()=> this.putPropertyNotes(id));
    // }

    deleteTile=(id)=>{
        console.log('deleteTile: ', id);
        let {tiles} = this.state;
        tiles = tiles.filter((tile)=> tile.id !== id);
        this.setState({tiles}, ()=> this.deletePropertyNotes(id));
    }

    handleSort=()=> {
        console.log('handleSort');
        let {tiles} = this.state;
        this.setState({loader: true,//[tiles[this.dragTile.current].id, tiles[this.dragTileOver.current].id], 
            dragStart: false},()=>{
            const temp = tiles[this.dragTile.current];
            tiles[this.dragTile.current] = tiles[this.dragTileOver.current];
            tiles[this.dragTileOver.current] = temp;
            
            this.setState({tiles}, ()=> 
            setTimeout(() => {
                tiles = tiles.map((tile, index)=> {
                    tile.displayOrder = (index+1)
                    return tile;
                });
                this.setState({loader: false, tiles}, ()=> setTimeout(() => {
                    this.updateAllNotes();
                }, 1000));
            }, 500));
        });
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        console.log('onSortEnd: ', oldIndex, newIndex);
        this.setState(({tiles}) => ({
            tiles: arrayMoveImmutable(tiles, oldIndex, newIndex),
        }));
    };

    updateAllNotes=()=>{
        console.log('updateAllNotes');
        //let {tiles} = this.state;
        const {tiles} = this.state;
        tiles.map((tile)=> this.putPropertyNotes(tile.id));
        // this.setState({
        //     tiles: tiles.map((tile, index)=> ({...tile, displayOrder: (index+1)}))
        // }, ()=> tiles.map((tile)=> this.putPropertyNotes(tile.id)));
    }

    getPropertyNotes=(loader=true)=>{
        this.setState({mainLoader: loader});
        let propertyId = this.props.propertySelected && this.props.propertySelected.id;
        Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.property_api_base_url + 'notes?propertyId=' + propertyId,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleGetPropertyNotes',
        null,
        false,
        '',
        {
            // 'Content-Type': 'application/json',
            Authorization: Autoload.Utils.getAuthToken(),
        },
        true
        );
    }

    handleGetPropertyNotes(response){
        if(response.status && response.statuscode === 200){
            this.setState({tiles: response.result.sort((a, b)=> a.displayOrder - b.displayOrder)});
        }

        this.setState({mainLoader: false});
    }

    postPropertyNotes=(newNote)=>{
        this.setState({mainLoader: true});
        let propertyId = this.props.propertySelected && this.props.propertySelected.id;
        let payload = {
            ...newNote,
            propertyId
        }
        Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.property_api_base_url + 'notes',
        'POST',
        payload,
        null,
        false,
        true,
        false,
        this,
        'handlePostPropertyNotes',
        null,
        false,
        '',
        {
            // 'Content-Type': 'application/json',
            Authorization: Autoload.Utils.getAuthToken(),
        },
        true
        );
    }

    handlePostPropertyNotes(response){
        if(response.status && response.statuscode === 200){
            showNotification({
                message: 'Neuer Aushang erstellt.',
                icon: <img src={GreenTick} alt='green-tick'/>,
                id: 'addTile'
            });
            this.getPropertyNotes();
        }else{
            showNotification({
                message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
                icon: <img src={RedCross} alt='red-cross'/>,
                id: 'tileGeneralError'
            });
        }
    }

    putPropertyNotes=(id)=>{
        this.setState({individualLoader: id});
        const {tiles} = this.state;
        let payload = tiles.find((tile)=> tile.id === id);
        if(payload !== undefined){
            Autoload.HttpRequestAxios.HttpRequest(
                Autoload.Setting.property_api_base_url + 'notes/' + payload.id,
                'PUT',
                payload,
                null,
                false,
                true,
                false,
                this,
                'handlePutPropertyNotes',
                null,
                false,
                '',
                {
                    // 'Content-Type': 'application/json',
                    Authorization: Autoload.Utils.getAuthToken(),
                },
                true
            );
        }
    }

    handlePutPropertyNotes(response){
        if(response.status && response.statuscode === 200){
            showNotification({
                message: 'Änderungen übernommen.',
                icon: <img src={GreenTick} alt='green-tick'/>,
                id: 'updateTile'
            });
            this.getPropertyNotes(false);
        }else{
            showNotification({
                message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
                icon: <img src={RedCross} alt='red-cross'/>,
                id: 'tileGeneralError'
            });
        }

        this.setState({individualLoader: null});
    }

    deletePropertyNotes=(noteId)=>{
        this.setState({mainLoader: true});
        Autoload.HttpRequestAxios.HttpRequest(
            Autoload.Setting.property_api_base_url + 'notes/' + noteId,
            'DELETE',
            null,
            null,
            false,
            true,
            false,
            this,
            'handleDeletePropertyNotes',
            null,
            false,
            '',
            {
                // 'Content-Type': 'application/json',
                Authorization: Autoload.Utils.getAuthToken(),
            },
            true
        );
    }

    handleDeletePropertyNotes(response){
        if(response.status && response.statuscode === 200){
            showNotification({
                message: 'Aushang wurde gelöscht.',
                icon: <img src={RedTick} alt='red-tick'/>,
                id: 'tileDelete'
            });
            this.getPropertyNotes();
        }else{
            showNotification({
                message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
                icon: <img src={RedCross} alt='red-cross'/>,
                id: 'tileGeneralError'
            });
        }

        this.setState({openDeleteModal: false});
    }
  
    render() {
        const {minimize, tiles, loader, dragStart, mainLoader, openTenantPortalTilePreviewModal, openTileDetailModal, tileDataForPreview, individualLoader, openDeleteModal, deleteTileId} = this.state;
        console.log('tiles: ', tiles);
        return (
            <div 
            style={{
            width: '100%',
            background: 'white',
            boxShadow: '0 1px 3px rgba(0,0,0,.08)',
            borderRadius: '4px'
            }}>
                <Accordion
                classNames={{
                    label: 'tenant-administration-accordion-label',
                    contentInner: 'tenant-administration-accordion-inner-content',
                    control: 'tenant-administration-accordion-control',
                    icon: 'tenant-administration-accordion-icon',
                }}

                styles={{
                    content: {
                        display: false && 'none',
                        boxShadow: false && 'none',
                    }
                }}
                iconPosition='right'>
                    <Accordion.Item 
                    label={
                    <Group
                    position='apart'
                    style={{
                        padding: '12.1px 24px',
                        borderBottom: true && minimize === false ? '1px solid #dadae6' : 'none',
                        borderTopLeftRadius: '4px',
                        borderTopRightRadius: '4px',
                        borderBottomLeftRadius: minimize && '4px',
                        borderBottomRightRadius: minimize && '4px',
                        background: 'white',
                        cursor: 'pointer'
                    }}
                    onClick={()=> this.setState({minimize: !minimize})}>
                        <Group className='tenant-administration-tiles-section'>
                            <h5 
                            style={{
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: '#0e0e1d',
                                marginBottom: '0px'
                            }}
                            className={'tenant-administration-display-messages-heading tooltips'}>
                                Aushänge
                            </h5>
                            <SwitchContainer
                            checked={true}
                            switchText={'aktivieren'}
                            disabled={false}
                            onChange={()=> null}
                            />
                        </Group>
                        {true && (!minimize ? <ExpandMoreIcon style={{color: '#13135C'}} /> : <NavigateNextIcon style={{color: '#13135C'}} />)}
                    </Group>}>
                        <div style={{padding: '12.1px 24px 30px'}}>
                            <Stack justify={'center'} align='center'>
                                {mainLoader ?
                                <Group position='center' align='center' style={{height: '500px'}}>
                                    <Loader/>
                                </Group>
                                :
                                <Group style={{width: '100%'}} position='left'>
                                    <SimpleGrid cols={2} style={{width: '100%'}}>
                                        {tiles.length > 0 &&
                                        // <SortableList 
                                        // items={tiles}
                                        // // handleSort={this.handleSort}
                                        // // dragTileOver={this.dragTileOver}
                                        // // dragTile={this.dragTile}
                                        // // setState={(state)=> this.setState(state)}
                                        // // dragStart={dragStart} 
                                        // loader={loader}
                                        // deleteTile={(tileId)=> this.deleteTile(tileId)}
                                        // updateTitle={(tileId, title)=> this.updateTitle(tileId, title)}
                                        // updateHtml={(tileId, body)=> this.updateHtml(tileId, body)}
                                        // updateIsActive={(tileId, isActiveStatus)=> this.updateIsActive(tileId, isActiveStatus)}
                                        // onSortEnd={this.onSortEnd}
                                        // update={(id)=> this.putPropertyNotes(id)}
                                        // axis={'xy'}
                                        // useDragHandle
                                        // />
                                        tiles//.sort((a, b)=> a.displayOrder - b.displayOrder)
                                        .map((tile, index)=> 
                                        // individualLoader === tile?.id ?
                                        // <Group position='center' align='center' 
                                        // style={{
                                        //     height: '500px',
                                        //     background: '#F7F7FA',
                                        //     boxShadow: '0 1px 3px rgba(0,0,0,.08)',
                                        //     borderRadius: '4px',
                                        //     width: '100%'
                                        // }}>
                                        //     <Loader/>
                                        // </Group>
                                        // :
                                        <Group
                                        // draggable
                                        // onDragStart={() => {
                                        //     this.dragTile.current = index;
                                        //     this.setState({dragStart: true});
                                        // }}
                                        // onDragEnter={() => (this.dragTileOver.current = index)}
                                        // onDragEnd={this.handleSort}
                                        // onDragOver={(e) => e.preventDefault()}
                                        style={{
                                            background: '#F7F7FA',
                                            boxShadow: '0 1px 3px rgba(0,0,0,.08)',
                                            borderRadius: '4px',
                                            width: '100%',
                                            padding: '20px',
                                            height: (dragStart || loader) ? '150px' : '500px', // loader.find((tileId)=> tileId === tile.id) !== undefined
                                            //transition: 'height 0.02s',
                                        }}
                                        >
                                            <Group noWrap style={{width: '100%'}}>
                                                <DragIndicatorIcon
                                                style={{
                                                    width: '16px', 
                                                    color: '#727293', 
                                                    cursor: dragStart ? 'grabbing' : 'grab',
                                                    marginTop: '4px'
                                                }}
                                                />
                                                {!loader && !dragStart && //loader.find((tileId)=> tileId === tile.id) === undefined
                                                <Group position='apart' style={{width: '100%'}}>
                                                    <Group position='apart' align={'center'}>
                                                        <VisibilityIcon
                                                        style={{
                                                            cursor: 'pointer',
                                                            //color: '#5555FF',
                                                            fontSize: '16px',
                                                            opacity: 0.4,
                                                            marginTop: '4px'
                                                        }} 
                                                        onClick={()=> this.setState({openTenantPortalTilePreviewModal: true, tileDataForPreview: tile})}
                                                        />
                                                        <SwitchContainer
                                                        checked={tile?.isActive}
                                                        switchText={'aktivieren'}
                                                        disabled={false}
                                                        onChange={()=> this.updateTile(tile?.id, 'isActive', !tile?.isActive, true)}
                                                        />
                                                        {/* <div>
                                                            <ColorButton
                                                            text={'Vorschau'}
                                                            className='grey-btn'
                                                            onClick={()=> null}
                                                            />
                                                        </div> */}
                                                    </Group>
                                                    <Group>
                                                        {individualLoader === tile?.id ?
                                                        <Loader size={'xs'}/>
                                                        :
                                                        <img src={CloseButtonIcon} style={{cursor: 'pointer', padding: '0px 10px', marginTop: '4px'}} onClick={()=> this.setState({openDeleteModal: true, deleteTileId: tile.id})}/>}
                                                    </Group>
                                                </Group>}                                        
                                            </Group>
                                            
                                            <TilesForTenantPortal
                                            body={tile?.body}
                                            id={tile?.id}
                                            title={tile?.title}
                                            displayOrder={tile?.displayOrder}
                                            loader={loader}
                                            dragStart={dragStart}
                                            index={index}
                                            // updateTitle={(title)=> this.updateTitle(tile.id, title)}
                                            // updateHtml={(html)=> this.updateHtml(tile.id, html)}
                                            updateTitle={(title, onBlur=false)=> this.updateTile(tile?.id, 'title', title, onBlur)}
                                            updateHtml={(body, onBlur=false)=> this.updateTile(tile?.id, 'body', body, onBlur)}
                                            // deleteTile={()=> this.setState({openDeleteModal: true})}
                                            />
                                        </Group>)
                                        }
                                        <Group 
                                        style={{
                                            background: '#F7F7FA', 
                                            padding: '40px',
                                            boxShadow: '0 1px 3px rgba(0,0,0,.08)',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                            //width: '433px',
                                            height: (dragStart || loader) ? '150px' : '500px',
                                            //transition: 'height 0.02s',
                                            width: '100%',
                                            opacity: dragStart ? 0.6 : 1
                                        }}
                                        position='center'
                                        align={'center'}
                                        onClick={()=> this.addTile()}>
                                            {loader ?//loader.find((tileId)=> tileId === id) !== undefined ?
                                            <Group position='center' align={'center'}>
                                                <Loader/>
                                            </Group>
                                            :
                                            <span style={{
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                color: '#727293'
                                            }}>Hinzufügen</span>}
                                        </Group>
                                    </SimpleGrid>
                                </Group>}
                            </Stack>
                        </div>
                    </Accordion.Item>
                </Accordion>


                {openTenantPortalTilePreviewModal &&
                <TenantPortalTilePreviewModal
                open={openTenantPortalTilePreviewModal}
                className={'expandImageModal'}
                toggle={()=> this.setState({openTenantPortalTilePreviewModal: false, tileDataForPreview: null, openTileDetailModal: false})}
                modalContent={
                <Stack className='dashboard-general-card' onClick={()=> this.setState({openTileDetailModal: true, openTenantPortalTilePreviewModal: false})} style={{minWidth: '360px', cursor: 'pointer', marginTop: '200px'}} spacing={'lg'}>
                    {<>
                        <Group position='apart'>
                            <Group noWrap align='flex-end'>
                                <span className='dashboard-general-card-heading'>{tileDataForPreview?.title ? tileDataForPreview?.title : 'No title available'}</span>
                            </Group>
                            <Group className='dashboard-general-card-all' onClick={()=> this.setState({openTileDetailModal: true, openTenantPortalTilePreviewModal: false})} align={'flex-end'}>
                                <span style={{cursor: 'pointer'}}>Mehr anzeigen</span>
                            </Group>
                        </Group>
                        {true ?
                        <Stack>
                            <div style={{overflowY: 'scroll', height: '180px'}} dangerouslySetInnerHTML={{__html: tileDataForPreview?.body}}/>
                        </Stack>
                        :
                        <Stack align={'center'} justify={'center'} style={{height: '100%'}} className='empty-state-container-dashboard'>
                            {/* <EmptyState
                            icon={messagesIcon}
                            title='Es sind noch keine Nachrichten vorhanden.'
                            subText='Nachrichten, die Ihr Vermieter versendet, werden Ihnen hier angezeigt.'
                            subTextClassName='empty-state-subtext-dashboard'
                            /> */}
                        </Stack>
                        }
                    </>}
                </Stack> 
                }
                />}
                {openTileDetailModal &&
                <TileDetailsModal
                open={openTileDetailModal}
                toggle={()=> this.setState({openTenantPortalTilePreviewModal: true, openTileDetailModal: false})}
                tile={tileDataForPreview}
                />}
                {openDeleteModal &&
                <DeleteModal
                open={openDeleteModal}
                className="delete-rent-modal"
                toggle={()=> this.setState({openDeleteModal: false, deleteTileId: null})}
                record={null}
                handleDelete={() => this.deleteTile(deleteTileId)}
                // showInfo={[this.props.propertyData.name,]}
                rightButtonText={'Löschen'}
                leftButtonText={'Abbrechen'}
                type={lang.property.text_property}
                showtext={false}
                showDeleteText={false}
                textShowModal={'Aushang löschen'}
                typeText={'Sind Sie sicher, dass Sie den Aushang löschen wollen? Alle Inhalte gehen unwiderruflich verloren.'}
                modalId="delete-notes"
                />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};
export default connect(mapStateToProps)(TenantAdministrateTilesSection);
