import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import PdfViewer from '../PdfViewer/PdfViewer';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Checkbox, Group, Stack, TextInput } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import { NumericFormat } from 'react-number-format';
import dayjs from 'dayjs';
import accounting from 'accounting';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class UpdateTenantRentModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    newRentalPayments: null,
    recievedRent: null,
    receivedNetRent: null, 
    receivedAdvancePayment: null,
    rentalPaymentsForModal: []
  };

  descriptionMsgStyle={
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#0e0e1d',
  }
  
  constructor(props) {
    super(props);
  }
  
  componentDidMount(){
    const {selectedRentModalData, rentalPaymentsForModal} = this.props;
    this.setState({receivedRent: selectedRentModalData.receivedRent, receivedNetRent: selectedRentModalData.receivedNetRent, receivedAdvancePayment: selectedRentModalData.receivedAdvancePayment, newRentalPayments: selectedRentModalData, rentalPaymentsForModal});
  }
  
  render() {
    const { open, toggle, className, selectedRentModalData } = this.props;
    const { receivedRent, receivedNetRent, receivedAdvancePayment, rentalPaymentsForModal } = this.state;
    
    return (
      <CustomModal
        heading={'Zahlungseingang erfassen'}
        headingClassName={'tenant-rent-tracking-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
        style={{
            width: '340px'
        }}
      >
        <Stack>
            <Stack>
              <Stack spacing={6}>
                <Group>
                  <span>Mieter: <span style={{fontWeight: 400}}>{`${selectedRentModalData.tenant.fullName} (${selectedRentModalData.tenant.rentalUnitName})`}</span></span>
                </Group>
                <Group>
                  <span>Monat: <span style={{fontWeight: 400}}>{`${dayjs(selectedRentModalData.rentalMonth).format('MMMM YYYY')}`}</span></span>
                </Group>
                <Group>
                  <Checkbox
                  classNames={{input: 'tenants-list-checkbox-rental-income-input', inner: 'tenants-list-checkbox-rental-income-inner'}}
                  checked={receivedRent >= selectedRentModalData.expectedRent}
                  label={`Vertragsmiete (${accounting.formatMoney(selectedRentModalData.expectedRent, '€', 2, '.', ',', '%v %s')}) erhalten`}
                  styles={{
                    label: {
                      fontSize: '14px',
                      marginBottom: 0
                    }
                  }}
                  onChange={()=> {
                    // var newRentalPayments= rentalPaymentsForModal.map((rp)=> {
                    //   if(rp.id === selectedRentModalData.id){
                    //     rp.receivedRent = (selectedRentModalData.receivedRent >= selectedRentModalData.expectedRent) ? 0 : selectedRentModalData.expectedRent;
                    //     return rp;
                    //   }else{
                    //     return rp;
                    //   }
                    // });

                    this.setState({//newRentalPayments, 
                      receivedRent: ((receivedRent >= selectedRentModalData.expectedRent) ? 0 : selectedRentModalData.expectedRent),
                      receivedNetRent: ((receivedNetRent >= selectedRentModalData.expectedNetRent) ? 0 : selectedRentModalData.expectedNetRent),
                      receivedAdvancePayment: ((receivedAdvancePayment >= selectedRentModalData.expectedAdvancePayment) ? 0 : selectedRentModalData.expectedAdvancePayment)
                    });
                  }}
                  />
                </Group>
              </Stack>
              <Stack spacing={12}>
                <span className='add-allocation-sub-headings'>Abweichende Mietzahlung erfassen</span>
                <Stack spacing={0}>
                  {/* <span className='rental-income-rent-received-modal-form-label'>Abweichenden Mieteingang erfassen</span> */}
                  <span className='rental-income-rent-received-modal-form-label'>Erhaltene Kaltmiete</span>
                  {/* <NumericFormat
                  className={`form-control ${receivedRent >= selectedRentModalData.expectedRent ? 'rental-income-payment-column-full-paid-modal' : ((receivedRent > 0) && (receivedRent < selectedRentModalData.expectedRent)) ? 'rental-income-payment-column-partial-paid-modal' : 'rental-income-payment-column-not-paid-modal'}`}
                  value={receivedRent}
                  thousandSeparator="." 
                  decimalSeparator=","
                  onValueChange={(values, sourceInfo) => {
                    // var newRentalPayments= rentalPaymentsForModal.map((rp)=> {
                    //   if(rp.id === selectedRentModalData.id){
                    //     rp.receivedRent = values.floatValue;
                    //     return rp;
                    //   }else{
                    //     return rp;
                    //   }
                    // });
                    this.setState({//newRentalPayments, 
                      receivedRent: values.floatValue});
                  }}
                  suffix={' €'}
                  //onKeyPress={this.handleEnterKeyPress}
                  placeholder='0,00 €'
                  // onFocus={()=> this.setState({vatPercentInFocus: true})}
                  // onBlur={()=> this.setState({vatPercentInFocus: false})}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  allowNegative={true}
                  style={{paddingLeft: 10, background: receivedRent >= selectedRentModalData.expectedRent ? '#e8f4ea' : ((receivedRent > 0) && (receivedRent < selectedRentModalData.expectedRent)) ? '#f7e3cd' : '#FFCBCB'}}
                  /> */}

                  <NumericFormat
                  className={`form-control ${receivedNetRent >= selectedRentModalData.expectedNetRent ? 'rental-income-payment-column-full-paid-modal' : ((receivedNetRent > 0) && (receivedNetRent < selectedRentModalData.expectedNetRent)) ? 'rental-income-payment-column-partial-paid-modal' : 'rental-income-payment-column-not-paid-modal'}`}
                  value={receivedNetRent}
                  thousandSeparator="." 
                  decimalSeparator=","
                  onValueChange={(values, sourceInfo) => {
                    // var newRentalPayments= rentalPaymentsForModal.map((rp)=> {
                    //   if(rp.id === selectedRentModalData.id){
                    //     rp.receivedRent = values.floatValue;
                    //     return rp;
                    //   }else{
                    //     return rp;
                    //   }
                    // });
                    this.setState({//newRentalPayments, 
                      receivedNetRent: values.floatValue});
                  }}
                  suffix={' €'}
                  //onKeyPress={this.handleEnterKeyPress}
                  placeholder='0,00 €'
                  // onFocus={()=> this.setState({vatPercentInFocus: true})}
                  // onBlur={()=> this.setState({vatPercentInFocus: false})}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  allowNegative={true}
                  style={{paddingLeft: 10, background: receivedNetRent >= selectedRentModalData.expectedNetRent ? '#e8f4ea' : ((receivedNetRent > 0) && (receivedNetRent < selectedRentModalData.expectedNetRent)) ? '#f7e3cd' : '#FFCBCB'}}
                  />
                </Stack>
                <Stack spacing={0}>
                  <span className='rental-income-rent-received-modal-form-label'>Erhaltene Vorauszahlungen</span>
                  <NumericFormat
                  className={`form-control ${receivedAdvancePayment >= selectedRentModalData.expectedAdvancePayment ? 'rental-income-payment-column-full-paid-modal' : ((receivedAdvancePayment > 0) && (receivedAdvancePayment < selectedRentModalData.expectedAdvancePayment)) ? 'rental-income-payment-column-partial-paid-modal' : 'rental-income-payment-column-not-paid-modal'}`}
                  value={receivedAdvancePayment}
                  thousandSeparator="." 
                  decimalSeparator=","
                  onValueChange={(values, sourceInfo) => {
                    this.setState({
                      receivedAdvancePayment: values.floatValue});
                  }}
                  suffix={' €'}
                  placeholder='0,00 €'
                  decimalScale={2}
                  fixedDecimalScale={true}
                  allowNegative={true}
                  style={{paddingLeft: 10, background: receivedAdvancePayment >= selectedRentModalData.expectedAdvancePayment ? '#e8f4ea' : ((receivedAdvancePayment > 0) && (receivedAdvancePayment < selectedRentModalData.expectedAdvancePayment)) ? '#f7e3cd' : '#FFCBCB'}}
                  />
                </Stack>
                
              </Stack>
              
            </Stack>
            <Group position='apart'>
                <div>
                    <ColorButton
                    text={'Abbrechen'}
                    className='gray-btn'
                    onClick={()=> toggle()}
                    />
                </div>
                <div>
                    <ColorButton
                    text={'Speichern'}
                    className='purple-btn'
                    onClick={()=> {
                      var newRentalPayments= rentalPaymentsForModal.map((rp)=> {
                        if(rp.id === selectedRentModalData.id){
                          rp.receivedRent = receivedRent;
                          return rp;
                        }else{
                          return rp;
                        }
                      });
                      this.props.saveRentValues(newRentalPayments, receivedRent, receivedNetRent, receivedAdvancePayment, selectedRentModalData.id)
                    }}
                    />
                </div>
            </Group>
        </Stack>
      </CustomModal>
    );
  }
}

export default UpdateTenantRentModal;
