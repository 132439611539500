import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import PdfViewer from '../PdfViewer/PdfViewer';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Checkbox, Grid, Group, Stack, TextInput } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import { NumericFormat } from 'react-number-format';
import accounting from 'accounting';
import dayjs from 'dayjs';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class TransactionDetailModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE
  };
  
  constructor(props) {
    super(props);
  }
  
  render() {
    const { open, toggle, className, transaction } = this.props;
    
    return (
      <CustomModal
        heading={transaction.counterpartName}
        headingClassName={'update-received-rent-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
        //style={{maxWidth: '450px'}}
      >
        <Stack>
            <Stack>
                <Grid gutter={'xl'} grow>
                    <Grid.Col span={6}>
                        <Stack spacing={0}>
                            <span className='app-label text-xs'>Sender / Empfänger</span>
                            <span className='text-img-wrapper' style={{fontWeight: '300'}}>{transaction.counterpartName}</span>
                        </Stack>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Stack spacing={0}>
                            <span className='app-label text-xs'>Datum</span>
                            <span className='text-img-wrapper' style={{fontWeight: '300'}}>{dayjs(transaction.bankBookingDate).format(dayjs(transaction.bankBookingDate).isSame(dayjs()) ? 'hh:mm Uhr' : 'DD.MM.YYYY')}</span>
                        </Stack>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Stack spacing={0}>
                            <span className='app-label text-xs'>Bankname</span>
                            <span className='text-img-wrapper' style={{fontWeight: '300'}}>{transaction.counterpartBankName}</span>
                        </Stack>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Stack spacing={0}>
                            <span className='app-label text-xs'>IBAN</span>
                            <span className='text-img-wrapper' style={{fontWeight: '300'}}>{transaction.counterpartIban}</span>
                        </Stack>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Stack spacing={0}>
                            <span className='app-label text-xs'>BIC</span>
                            <span className='text-img-wrapper' style={{fontWeight: '300'}}>{transaction.counterpartBic}</span>
                        </Stack>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Stack spacing={0}>
                            <span className='app-label text-xs'>Transaktionsart</span>
                            <span className='text-img-wrapper' style={{fontWeight: '300'}}>{transaction.transactionType}</span>
                        </Stack>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Stack spacing={0}>
                            <span className='app-label text-xs'>Betrag</span>
                            <span className='text-img-wrapper' style={{fontWeight: '300'}}>{accounting.formatMoney(transaction.amount, '€', 2, '.', ',', '%v %s')}</span>
                        </Stack>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Stack spacing={0}>
                            <span className='app-label text-xs'>Beschreibung</span>
                            <span className='text-img-wrapper' style={{fontWeight: '300'}}>{transaction.purpose}</span>
                        </Stack>
                    </Grid.Col>
                </Grid>
            </Stack>
            {/* <Group position='right'>
                <div>
                    <ColorButton
                    text={'Schl'}
                    className='gray-btn'
                    onClick={()=> toggle()}
                    />
                </div>
            </Group> */}
        </Stack>
      </CustomModal>
    );
  }
}

export default TransactionDetailModal;
