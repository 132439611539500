import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import PdfViewer from '../PdfViewer/PdfViewer';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import { Group, Loader } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import { load, unload } from '@finapi/web-form';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class FinApiWebFormModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    loader: false
  };

  descriptionMsgStyle={
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#0e0e1d',
  }
  
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    const {toggle, getBankConnections, deleteBankConnection, webFormUrl, updateBankConnectionInProgress, getBankConnectionById} = this.props;

    let url = webFormUrl;
    if(url){
      this.setState({loader: true});
      // Split the URL at the last '/' character
      let parts = url.split('/');

      // Join the first parts (excluding the last element) to get the base URL
      let baseUrl = parts.slice(0, -1).join('/');

      // The last part will be the ID
      let id = parts[parts.length - 1];

      console.log("Base URL:", baseUrl);
      console.log("ID:", id);

      const callToggle=()=> toggle();
      const callGetBankConnections=()=> getBankConnections();
      const callGetBankConnectionById=()=> getBankConnectionById();
      const callDeleteBankConnection=()=> deleteBankConnection();
      const callToggleLoaderFalse=()=> this.setState({loader: false});
      load(document.getElementById('web-form-id'), { 
          token: id,
          targetUrl: 'https://webform-sandbox.finapi.io', //baseUrl,
          language: "de"
        },
        {
          onLoaded: function() {
            // handle "onLoaded" event
            console.log('web-form: onLoaded');
            callToggleLoaderFalse();
          },
          onComplete: function() {
            // handle "onComplete" event
            console.log('web-form: onComplete');
            callToggle();
            if(updateBankConnectionInProgress){
              callGetBankConnectionById();
            }else{
              callGetBankConnections();
            }
          },
          onFail: function() {
            // handle "onFail" event
            console.log('web-form: onFail');
            if(!updateBankConnectionInProgress){
              callDeleteBankConnection();
            }
            callToggleLoaderFalse();
            showNotification({
              icon: <img src={RedCross} alt='RedCross' />,
              message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
              id: 'generalErrorMessage'
            });
            callToggle();
            unload(document);
          },
          onAbort: function() {
            // handle "onAbort" event
            console.log('web-form: onAbort');
            if(!updateBankConnectionInProgress){
              callDeleteBankConnection();
            }
            callToggleLoaderFalse();
            callToggle();
            unload(document);
          },
          onLoadError: function() {
            // handle "onLoadError" event
            console.log('web-form: onLoadError');
            if(!updateBankConnectionInProgress){
              callDeleteBankConnection();
            }
            callToggleLoaderFalse();
            showNotification({
              icon: <img src={RedCross} alt='RedCross' />,
              message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
              id: 'generalErrorMessage'
            });
            callToggle();
            unload(document);
          }
        },
        document
      );
    }
  }

  componentWillUnmount(){
    unload(document);
  }
  
  render() {
    const { open, toggle, className, heading } = this.props;
    const { loader, isMobile, isTablet } = this.state;
    
    return (
      <CustomModal
        heading={heading ? heading : 'finApi'}
        open={open}
        toggle={() => {
          toggle();
        }}
        //hideClose={true}
        className={`${className}`}
        style={{minWidth: (isMobile || isTablet) ? 'auto' : '1200px'}}
        //hideCloseButton={true}
      >
        <Group id={'web-form-id'}/>
        {loader &&
        <Group position='right'>
          <div>
            <ColorButton
            text={'Abbrechen'}
            className={'purple-btn'}
            onClick={()=> toggle()}
            />
          </div>
        </Group>}
      </CustomModal>
    );
  }
}

export default FinApiWebFormModal;
